.oval-dm-1{
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #F9BC91;
}
@media screen and (max-width: 1199px) and (min-width: 992px){
.oval-dm-1{
    width: 1.5%;
    top: 73%;
    left: 7%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-1{
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-2 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 22.16%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color: #F9BC91;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-2 {
    width: 1.5%;
    top: 73%;
    left: 20.83%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-2 {
    top: 20%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-3{
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color:#F9BC91;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-3{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-3{
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-4 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 54%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color:#F9BC91;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-4{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 48.5%;
    right: 507px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-4 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-5 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 67%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-5{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 62.3%;
    right: 365px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-5 {
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-6 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 80%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-6{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-6 {
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-dm-7 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 93%;
  right: 10%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-dm-7 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
  }
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-dm-7 {
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.company-info-dm {
   position: absolute;
    top: 40%;
    left: 34.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.company-info-dm {
    position: absolute;
    top: 50%;
    left: 30%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.company-info-dm {
    top: 35%;
    left: 30%;
}
}
.dimensions-dm{
  position: absolute;
    top: 40%;
    bottom: 30%;
    left: 52%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.dimensions-dm {
    top: 50% !important;
    left: 46% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.dimensions-dm {
    position: absolute;
    top: 50% !important;
    left: 30% !important;
}
}
.additional-dm{
  position: absolute;
    top: 40%;
    left: 76.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.additional-dm {
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.additional-dm {
    top: 80% !important;
    left: 30% !important;
}
}
.back-box {
  position: absolute;
  top: 65%;
  bottom: 10%;
  left: 8%;
  right: 8%;
  height: 4%;
  width: 86%;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.back-box {
    top: 77%;
    left: 8%;
    width: 83% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.back-box {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
}
.color-box {
  position: absolute;
    top: 65%;
    left: 8%;
    height: 4px;
    width: 47%;
    background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.color-box {
  top: 77%;
  left: 8%;
  height: 4px;
  width: 41%;
  background-color: #f9bc91;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.color-box {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 50%;
    width: 2%;
}
}
.dm-box{
margin-top: 3%;
box-sizing: border-box;
height: 34px;
width: 109px;
border: 1px solid #d8d8d8;
background-color: var(--main-bg-color);
}

.dimension-button{
  width: 32%;
  margin-left: 60%;
}
.button-continue-dimension{
  border: none;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
}
.dimension-continue{
  color: #ffffff;
}
.st-line-dimension{
  margin-top: 4.5% !important;
  margin-left: 3.3%;
  height: 1px;
  width: 1048px;
  border-bottom: 1px solid #d8d8d8;
}

.arrow-icon-1 {
  margin-top: -16%;
  margin-left: 80%;
}
.confirmmsg-dimension{
  color:red;
  margin-top: -8%;
  margin-left: 28%;
  }
  @media screen and (min-width: 992px) and (max-width: 1199px){
    .color-line-dimension{
      left: 58%;
      width: 340%;
    }
    .dimension-hide{
      margin-top: 13% !important;
    }
    .arrow-image{
      height:99%;
      width:200%;
    }
     .product-dimention{
        margin-left: 3% !important;
        
      }
      .oval-dimension3{
        left: 395% !important;
        width: 10% !important;
        height: 13% !important;
      }
      .arrow-icon-1 {
        margin-top: -20%;
        margin-left: 92%;
      }
      .dimension-button {
        width: 52%;
        margin-left: 60%;
        margin-top: -4%;
    }
    .additional-back{
      margin-bottom: 2%;
      margin-left: 3% !important;
      margin-top: 3%;
    }
    .item-information1 {
      margin-left: 3.5%;
      margin-top: 15%;
      font-size: 16px;
  }
    }
  @media screen and (min-width: 768px) and (max-width: 991px){
    .confirmmsg-dimension{
      color:red;
      margin-top: -14%;
      margin-left: 28%;
      }
    .arrow-image{
      height:220%;
      width:390%;
    }
    .color-line-dimension{
      top: 5% !important;
      left: 20% !important;
      height: 50% !important;
      width: 2% !important;
    }
    .oval-dimension3{
      top: 50% !important;
      left: 18% !important;
      height: 12px;
      width: 12px;
    }
    .dimensions-dm{
      position: absolute;
      top: 50%!important;
      left: 30%!important;
    }
    .arrow-icon-1 {
      margin-top: -30% !important;
      margin-left: 95% !important;
    }
    .dm-box{
      width: 90% !important;
    }
    .dimension-button {
      width: 52%;
      margin-left: 40%;
      margin-top: 1%;
  }
  .additional-back{
    margin-bottom: 2%;
    margin-left: 3% !important;
    margin-top: 10%;
  }
  }
  
  @media screen and (min-width: 601px) and (max-width: 767px){
    .confirmmsg-dimension{
      color:red;
      margin-top: -14%;
      margin-left: 28%;
      }
    .arrow-image{
      height:100%;
      width:100%;
      margin-top: 150%;
    }
    .color-line-dimension{
      top: 50%;
      left: 20%;
      height: 50%;
      width: 2%;
    }
    .oval-dimension3{
      top: 50%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .dimensions-dm{
      top: 50% !important;
      left: 30% !important;
    }
    .arrow-icon-1 {
      margin-top: -62% !important;
      margin-left: 98%;
      height: 20%;
      width: 20%;
    }
    .dm-box{
      width: 90% !important;
    }
    .dimension-button {
      width: 52%;
      margin-left: 36% !important;
  }
  }

  .button-save-dimension{
    background-color: Transparent;
    cursor: pointer;
    box-sizing: border-box;
    height: 34px;
    width: 151px;
    border: 1px solid #49a7a2;
    border-radius: 18px;
    margin-right: 2%;
  }
  .product-dimention{
    margin-left: 3%;
    margin-top: 3%;
    font-size: 16px;
  }

  .dimention-box1{
    margin-left: 4%;
  }
  .all-rights-reserved-dimension{
    position: absolute;
    top: 80% ;
    bottom: 23px;
    /* left: 83%; */
    left:85.9%;
    right: 112px;
    height: 14px;
    width: 20%;
    color: #77FCFB;
    font-family: var(--main-font-family);
    font-size: var( --disclaimer-font-size);
    letter-spacing: 0;
    line-height: 14px;
  }
  .all-rights-reserved-dimension1{
    z-index: 1;
    margin-top: 45.1%;
    margin-left: 8.5%;
    color: #77FCFB;
   font-family: var(--main-font-family);
    font-size: var( --disclaimer-font-size );
   }
   @media screen and (max-width: 1200px) and (min-width: 993px){
    .all-rights-reserved-dimension{
    left: 64%;
    }
   }
 
   @media screen and (max-width: 992px) and (min-width: 601px)
   {
    .all-rights-reserved-dimension{
     top: 80%;
     left:47%;
      z-index: 1;
      height: 14px;
      width: 100px;
      color: #77FCFB;
      font-family: var(--main-font-family);
      font-size: var(--disclaimer-font-size);
      letter-spacing: 0;
      line-height: 14px;
    }
    .all-rights-reserved-dimension1{
      z-index: 1;
      width: 30%;
      margin-top: 44%;
      margin-left: 4%;
      color: #77FCFB;
     font-family: var(--main-font-family);
      font-size: var( --disclaimer-font-size );
     }
   }
   .bigCartonLabel{
    width: max-content !important;
   }
   .errormsg{
    color:red;
    margin-top: 3%;
  }
  @media screen and (min-width: 1367px){
    .all-rights-reserved-dimension1{
        margin-left: 11%;
      }
    .all-rights-reserved-dimension{
      left: 86%;
    }

   }