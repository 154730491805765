.packColor{
    width: 1050px;
    height: auto;
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 2%;
    padding-bottom: 2%;
    background-color: #F4F6F7;
    border: 1px solid #d8d8d8;
   
  }
  .info-icon-pack{
      position: absolute;
      margin-top: -22%;
      margin-left: 47%;
      height: 14%;
      width: 14%;
  
  }
  .info-icon-pack1 {
    position: absolute;
    margin-top: -22%;
    margin-left: 42%;
    height: 14%;
    width: 14%;
}
.info-icon-pack2 {
  position: absolute;
  margin-top: -23%;
  margin-left: 58%;
  height: 14%;
  width: 14%;
}
  .pack-label{
    margin-top: -4%;
    font-size: 14px;
  }
  .pack-component{
    font-size: 16px;
    font-weight: bold;
    margin-left: 57px;
    margin-top: -20px;
  }

  .remove{
    margin-left: 97%;
 
  }
  .removeWarn{
    margin-right: 33%;
    margin-top: 21%;
  }
  .removeWarn1{
    margin-right: 33%;
    margin-top: 15%;
  }
  .warningMessage {
    margin-top: 30%;
    margin-left: 5%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 20px;
    word-spacing: 1.5px;
}
.closePack{
  margin-left: 94%;
  margin-top: -3%;
}
.checkMessage{
  margin-left: 26%;
    font-weight: bold;
    font-size: 20px;
}
.checkMessage1{
  margin-top: 4%;
  margin-left: 8%;
  font-size: 14px;
  word-spacing: 1.5px;
}
  .component-type{
    margin-left: 10px;
  }
  .remove-img{
    margin-top: -5%;
     }

  .pack-component-dimension{
    font-weight: bold;
    font-size: 16px;
    margin-left: 35px;
    margin-top: 20px;
  }

  .arrow {
    border: solid #4ca4a4;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 37px;
    margin-top: 32px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .buttondiv-logout-warning1{
    position: relative !important;
    border-width: 2px;
    left: 6%;
    height: 34px !important;
    width: 120px !important;
    border-radius: 19px !important;
    background-color:white !important;
    border-color: #49A7A2 !important;
    color: black;
   }

   .pack-component-company{
    font-size: 16px;
    font-weight: bold;
    margin-left: 36px;
    margin-top: 15px;
   }

   .packColor-company{
    width: 1050px;
    height: auto;
    margin-left: -8px;
    margin-top: 4%;
    margin-bottom: 2%;
    margin-right: 2%;
    padding-bottom: 5%;
    background-color: #F4F6F7;
    border: 1px solid #d8d8d8;
   }
   .select-box {
    margin-top: 3%;
    box-sizing: border-box;
    width: 220px !important;
    border: 0px solid #d8d8d8;
    background-color: var(--main-bg-color);
  }

  @media screen and (max-width: 992px) and (min-width: 601px){
    .remove-img{
      margin-left: -1% !important;
    }
    .remove{
      margin-left: 95% !important;
    }
    .pack-box {
      margin-left: 4%;
    }
    .info-icon-pack{
      margin-top: -19%;
      margin-left: 39%;
    }
    .info-icon-pack1{
      margin-top: -19%;
      margin-left: 35%;
    }
    .info-icon-pack2{
      margin-top: -19%;
      margin-left: 47%;
    }
  }
  
 .company-readOnly{
      margin-top: 2%;
      box-sizing: border-box;
      height: 63px;
      width: 220px;
      border: 1px solid #d8d8d8;
      background-color: lightgrey;
    }
    