.alphabh{
  font-size: 16px;
  font-family: var(--main-font-family-bold);
}
.white-modal-file{
  position: absolute;
  top: 22%;
  left: 8.46%;
  height: 420px;
  width: 83%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--main-bg-color);
}
@media screen and (min-width: 992px) and (max-width: 1199px){
  .white-modal-file{
    position: absolute;
    /* top:21%; */
    top:17.2%;
    left: 1.5%;
    height: 56%;
    width: 70%;

  }
  .middle-box {
    top: 15vw !important;
    height: 56% !important;
  }
  .file-upload {
    top: 13vw !important;
}
}

@media screen and (min-width: 600px) and (max-width: 991px){
  .white-modal-file{
    /* top:21%; */
    top:17.2%;
    left: 4%;
    height: 55% !important;
    width: 50%;
  }
  .file-upload {
    top: 5vw !important;
  }
  .guide-line-icon {
    left: 54vw !important;
  }
  .guide-line-text {
    left: 58vw !important;
    top: -3.4vw !important;
  }
  .middle-box {
    top: 12vw !important;
    height: 56% !important;
  }
  .file-box2{
    top: -10vw !important;
  }
  .click-here-to-upload {
    top: 20% !important;
    left: 65% !important;
  }
}
.summary-screen-msg {
  position: absolute;
  top: 3%;
  left: 2%;
  height: 14px;
  width: 95%;
  color: #888E8E;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.gray-rectangle {
  position: absolute;
  top: 0px;
  bottom: 1189px;
  height: 48px;
  width: 1130px;
  background-color: rgba(238, 241, 242, 0.83);
}
.form-upload {
  position: absolute;
  top: 18px;
  left: 66px;
  height: 14px;
  width: 73px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
}
.excel-upload {
  position: absolute;
  top: 21px;
  left: 231px;
  height: 14px;
  width: 69px;
  color: #414141;
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.green-rectangle {
  position: absolute;
  top: 43px;
  left: 42px;
  height: 4px;
  width: 126px;
  background-color: #49a7a2;
}
.line {
  position: absolute;
  top: 13px;
  left: 749px;
  box-sizing: border-box;
  height: 22px;
  width: 1px;
  border: 1px solid #d8d8d8;
}
.product-path-digital {
  position: absolute;
  top: 17px;
  left: 766px;
  height: 14px;
  width: 324px;
  color: #5bbdbc;
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.bread-crumb {
  position: absolute;
  top: 1px;
  left: 79px;
  height: 6px;
  width: 10px;
}
.digital {
  position: absolute;
  top: 17px;
  left: 866px;
  height: 14px;
  color: #424242;
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.modal {
  top : 0 ;
  left: 0;
  right: 0;
  position: fixed;
  bottom: 0;
  /* background-color: red!important; */
}
.oval {
  position: absolute;
    top: 62%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 11%;
    width: 1.3%;
    border-radius: 10px;
    background-color: #F9BC91;
}

@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.straight-line1 {
  position: absolute;
  top: 65%;
  left: 8.5%;
  height: 4px;
  width: 14.5%;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
 .straight-line1 {
    top: 77%;
    left: 8%;
    width: 13.5%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.straight-line1 {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 15%;
    width: 2%;
}
}
.oval1 {
  position: absolute;
    top: 62%;
    bottom: 10%;
    left: 22.16%;
    right: 20%;
    height: 11%;
    width: 1.3%;
    background-color:#f9bc91;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval1 {
  width: 1.5%;
    top: 73%;
    left: 20.83%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px){
.oval1 {
    top: 20% !important;
    left: 18% !important;
    height: 12px !important;
    width: 12px !important;
}
}
.oval2 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color: #EEF1F2;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval2 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval2 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval3s {
  position: absolute;
  top: 75px;
  left: 441px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  background-color: #f9bc91;
}
.oval4s {
  position: absolute;
  top: 75px;
  left: 604px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  background-color: #f9bc91;
}
.oval5s {
  position: absolute;
  top: 75px;
  left: 747px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  background-color: #f9bc91;
}
.oval6s {
  position: absolute;
  top: 75px;
  left: 899px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  background-color: #f9bc91;
}
.oval7s {
  position: absolute;
  top: 75px;
  left: 1052px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  background-color: #f9bc91;
}
.gray-line {
  position: absolute;
    top: 65%;
    bottom: 10%;
    left: 8%;
    right: 8%;
    height: 4%;
    width: 86%;
    background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.gray-line {
    top: 77%;
    left: 8%;
    width: 83% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.gray-line {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
}
.group-information {
position: absolute;
top: 40%;
bottom: 10%;
left: 3.5%;
right: 42px;
height: 10%;
width: 83%;
color: var(--main-txt-color);
font-family: var(--main-font-family);
font-size: var( --base-font-size );
letter-spacing: 0;
line-height: 14px;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.group-information {
top: 5% !important;
left: 30% !important;
}
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.group-information {
position: absolute;
top: 50%;
bottom: 344px;
left: 3%;
right: 42px;
height: 14px;
width: 20%;
color: var(--main-txt-color);
font-family: var(--main-font-family);
font-size: var( --base-font-size );
letter-spacing: 0;
line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.group-information {
top: 5% !important;
left: 30% !important;
}
}

.company-info-f {
  position: absolute;
  top: 48px;
  left: 388px;
  height: 14px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.dimensions-information-f {
  position: absolute;
  top: 48px;
  left: 581px;
  height: 14px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.item-information-f {
  position: absolute;
  top: 48px;
  left: 721px;
  height: 14px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.additional-information-f {
  position: absolute;
  top: 48px;
  left: 854px;
  height: 14px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.file-information-f {
  position: absolute;
  top: 48px;
  left: 1021px;
  height: 14px;
  width: 1130px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}


.color-rectangle {
  position: absolute;
  top: 80px;
  left: 81px;
  height: 4px;
  width: 180px;
  width: 975px;
  background-color: #f9bc91;
}
.camera-back-fs {
  position: relative;
  left: 3.6%;
 display: flex;
 align-items: center;
 justify-content: center;
  height: 1.17%;
  width: 1.62%;
}

.cloudinaryBtn {
  position: absolute;
  left: 81px;
  top: 110px;
}
.file-information {
  position: absolute;
  top: 37%;
  left: 91%;
  height: 10%;
  width: 95%;
  right: 10%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.file-information {
    top: 50% !important;
    left: 88% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.file-information {
  top: 93% !important;
  left: 30% !important;
}
}
.headfix6 {
  margin-left: 115px;
  margin-top: 163px;
  position: absolute;
  height: 102px;
  background: #ffffff;
  width: 1130px;
  z-index: 1;
}
.headerName {
  font-size: 14px;
  font-family: var(--main-font-family-bold);
  margin-top: 25px;
}
.file-upload{
  position: relative;
  top: 10vw;
  left: 4%;
  height: 18px;
  width: 301px;
  color: #000000;
  font-family: var(--main-font-family);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}
.guide-line-icon{
  position: relative;
  left: 63vw;
  top: -1vw;
}
.guide-line-text{
  position: relative;
  left: 65vw;
  top: -2.4vw;
  color: #323232;
  width: 48%;
}
.omega{
  display: flex;
  flex-direction: row;
}
.omega1{
  margin-left: 38px;
}
.middle-box {
  position: relative;
  top: 11.5vw;
  left: 3vw;
  box-sizing: border-box;
  height: 73%;
  width: 93%;
  border: 1px solid #E4E5E5;
  background-color: #F9FCFD;
}
.file-box {
  position: relative;
  box-sizing: border-box;
  height: 48px;
  width: 70vw;
  border: 1px solid #E4E5E5;
  background-color: #FFFFFF;
}
.drop-your-files-here {
  position: relative;
  /* top: -20.5vw;*/
  right: 2.5vw;
  height: 18px;
  width: 140px;
  color: #494747;
  font-family: var(--main-font-family);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}
.box1 {
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #ECEEEE;
  border: 1px solid #E4E5E5;
}
.box2 {
  margin-left: 12.8vw;
  margin-top: -3.6vw;
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #FFFFFF;
  border: 1px solid #E4E5E5;
}
.box3 {
  margin-left: 25.4vw;
  margin-top: -3.5vw;
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #FFFFFF;
  border: 1px solid #E4E5E5;
}
.box4 {
  margin-left: 38vw;
  margin-top: -3.5vw;
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #FFFFFF;
  border: 1px solid #E4E5E5;
}
.box5 {
  margin-left: 50.8vw;
  margin-top: -3.6vw;
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #FFFFFF;
  border: 1px solid #E4E5E5;
}
.box6 {
  margin-left: 63.6vw;
  margin-top: -3.5vw;
  box-sizing: border-box;
  height: 48px;
  width: 12.8vw;
  background-color: #FFFFFF;
  border: 1px solid #E4E5E5;
}
.file-box2 {
  position: relative;
  top: -15vw;
  box-sizing: border-box;
  height: 4vw;
  width: 100%;
  background-color: #F2F8FA;
}
.file-icon {
  position: relative;
  top: 5vw;
  left: 35vw;
  height: 30vw;
  width: 30vw
}
.green-box {
  position: relative;
  left: 88%;
  height: 100%;
  width: 12%;
  background-color: #49a7a2;
  cursor: pointer;
}

.plus{
  position: relative;
  top: 0.75vw;
  left: 3.5vw;
  height: 2.5vw;
  width: 2.5vw;
  background-color: 1px solid #FFFFFF;
}
.image {
  position: relative;
  top: 0.75vw;
  left: 4.5vw;
  height: 15px;
  width: 50px;
  color: #000000;
  font-family: var(--main-font-family);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}
.image1{
  height: 16px;
  width: 9px;
  position: absolute;
  right: 50px;
  margin-top: 7px;
}
.image2{
  position: relative;
  top: 1vw;
  left: 3vw;
  height: 15px;
  width: 50px;
  color: #000000;
  font-family: var(--main-font-family);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}
.button-save-file{
  position: relative;
  background-color: Transparent;
  cursor: pointer;
  overflow: hidden;
  /* top: 560px; */
  left: 54vw;
  right: 210px;
  /* box-sizing: border-box; */
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
}
.button-save-file1{
  position: relative;
  background-color: Transparent;
  cursor: pointer;
  overflow: hidden;
  top:-90px;
  left: 45%;
  margin-top: 3% !important;
  margin-bottom: 2%;
  box-sizing: border-box;
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
}
.save-file{
  position: absolute;
  top: 9px;
  right: 63px;
  height: 15px;
  width: 27px;
  color: #3b3535 !important;
  font-family: var(--main-font-family) !important;
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 15px;
}
.button-continue-file {
  position: relative;
  border: none;
  cursor: pointer;
  overflow: hidden;
  left: 68%;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
}
.submm{
  margin-top: 10%;
}
.Print{
  position: absolute;
  border: none;
  cursor: pointer;
  overflow: hidden;
  margin-top: 120px;
  left: 690px;
 margin-bottom: 2%;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
}
.button-continue-file1 {
  position: relative;
  border: none;
  cursor: pointer;
  overflow: hidden;
  top: -90px;
  margin-top: 120px;
  left: 83%;
  margin-bottom: 2%;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color:#01c5c4;
}
.continue-file {
  position: absolute;
  top: 9px;
  right: 53px;
  height: 15px;
  width: 47px;
  color: #ffffff !important;
  font-family: var(--main-font-family) !important;
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 15px;
}
.click-here-to-upload {
  position: absolute;
  top: 40%;
  left: 70%;
  height: 15px;
  color: #ACAAAA;
  font-family: var(--main-font-family);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
}
.click-here-to-upload:hover {
  color:#49A7A2;
  text-decoration: none;
  cursor: pointer;
}
.FileUpload {
  height: 40px;
  background-color: #CBF3F3;
  margin-top: 2px;
  margin-left: -20px;
  width: 1075px;
  display: flex;
  flex-direction: row;  
}
.ome{
  height: 18px;
  width: 16px;
}
.alpha {
  margin-bottom: 29px;
  font-size: 14px;
  margin-top: -8px;
}
.FileData {
margin-bottom: 30px;

}
.dummy {
  
  display: flex;
  flex-direction: row;
}
.dummy4 {
  margin-left: 22px;
}
.dummy1 {
  height: auto;
  /* width: 18%; */
  margin-left: 15px;
  margin-top: 2px;
}
.dummy2 {
  font-size: 13px;
  font-family: var(--main-font-family-bold);  
}
.dummy3 {
font-size: 13px;
font-family: var(--main-font-family-regular);
color:#676767 ;
word-wrap: break-word;
}
.FileStructure {
  color: #0F8686;
  font-size: 14;
  font-family: var(--main-font-family-bold);
  margin-left:  45px;
  padding-top: 10px;
  
}
.group-file {
  margin-top: 3%;
  display: grid;
  grid-template-columns: 26% 27% 27% 26%;
    margin-left: 3%;
    font-size: 14px;
    font-weight: bold;
    font-family: var(--main-font-family-bold);
}
.group-value {
  margin-top: 1%;
    display: grid;
    grid-template-columns: 26% 27% 27% 26%;
    margin-left: 3%;
}

.grid-section{
  display: grid;
  grid-template-columns: 26% 26% 26% 26%;
}

.grid-section2{
  display: grid;
  grid-template-columns: 26% 26% 26%;
}

.grid-section3{
  display: grid;
  grid-template-columns: 26% 26% 26% 26% 26% 26%;
}
.rectangle {
  height: 1%;
  width: 10%;
  background-color: black;
  display:inline-flex;
}
.tab {
  overflow: hidden;
  border: 1px solid #E4E5E5;
  background-color: #FFFFFF;
}
 .tab button {
  width: 20%;
  background-color: #FFFFFF;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 13px;
  color: #000000;
  font-family: var(--main-font-family);
  display:inline-flex;
  display: table-cell;
    vertical-align: middle;
}
.tab button.active {
  background-color: #ECEEEE;
}
.tab button:hover {
  background-color: #ECEEEE;
}
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.packColor{
  width: auto;
  height: auto;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  padding-bottom: 2%;
  background-color: #F4F6F7;
  border: 1px solid #d8d8d8;
 
}

.file-boxUpload{
  position: absolute;
  top: 84%;
  box-sizing: border-box;
  height: 16%;
  width: 100%;
  background-color: #F2F8FA;
}
.File-value-url {
  margin-top: 1%;
    display: grid;
    grid-template-columns: 450px;
    margin-left: 3.9%;
}
.File-value {
  margin-top: 1%;
    display: grid;
    /* grid-template-columns: 450px; */
    margin-left: 3.9%;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .button-save-file1{
    left: 30%;
  }
  .button-continue-file1 {
    left: 79%;
  }
  .button-continue-file{
    left: 60%;
  }
}

@media screen and (min-width: 600px) and (max-width: 991px){
  .grid-section{
    display: grid;
    grid-template-columns: 26% 26% 26% 20%;
  }
  .header-fix4 {
    margin-left: 8.46%;
    /* margin-top: 12%; */
    margin-top: 9.72%;
    position: absolute;
    /* height: 16%; */
    height: 13.5%;
    background: #ffffff;
    width: 83.09%;
    z-index: 1;
    display: none;
  }
  .drop-your-files-here{
    right: 5.1vw;
  }

  .button-save-file1{
    left: 6%;
    appearance: textfield;
  }
  .button-continue-file1 {
    left: 70%;
    appearance: textfield;
  }
  .button-save-file{
    left: 50%;
  }
  .button-continue-file{
    left: 52%;
  }
}
.orange-line-file{
  position: absolute;
  top: 65%;
  left: 8%;
  height: 4%;
  width: 85%;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px){
.orange-line-file {
    top: 77%;
    left: 8%;
    width: 83% !important;
}
}
.oval5-file {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 80%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval5-file {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}

.oval6-file {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 93%;
    right: 10%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval6-file{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
.additional-information-file {
  position: absolute;
  top: 40%;
  left: 76.5%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.additional-information-file{
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
.file-information-file{
  position: absolute;
  top: 37%;
  left: 91%;
  height: 10%;
  width: 95%;
  right: 10%;
  color: var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.file-information-file {
    top: 50% !important;
    left: 88% !important;
}
.all-rights-reserved-fileupload{
  top: 78% !important;
  left: 64% !important;
}
}
@media screen and (max-width: 1200px){

  .all-rights-reserved-fileupload1 {
    margin-left: 4% !important;
  }
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
  .uploadimage{
    margin-top: 2%;
    margin-left: 1.5%;
    display: grid;
    grid-template-columns: 180px 180px !important;
    column-gap: 70px !important;
    height: 220% !important;
    overflow-y: auto !important;
  }
  .preview-disclaimer{
    margin-top: 30% !important;
  }
.all-rights-reserved-fileupload1 {
    margin-left: 4% !important;
    width: 30%;
    margin-top: 43% !important;
  }
.all-rights-reserved-fileupload{
  left: 47% !important;
  top: 80% !important;
}
.orange-line-file {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
.file-information-file{
  top: 90% !important;
  left: 30% !important;
}
.additional-information-file{
  top: 78% !important;
  left: 30% !important;
}
.oval5-file {
  top: 78%;
  left: 18%;
  height: 12px;
  width: 12px;

}

.oval6-file {
    top: 90%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.all-rights-reserved-fileupload {
  position: absolute;
  top: 79% !important;
  /* left: 83%; */
  left: 85.9%;
  height: 14px;
  width: 20%;
  color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size );
  letter-spacing: 0;
  line-height: 14px;
 }

.all-rights-reserved-fileupload1 {
  position: absolute;
  margin-top: 44.7%;
  margin-left: 8%;
  color: #77fcfb;
  font-family: var(--main-font-family-regular);
  font-size: var(--disclaimer-font-size);
}
.center{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
.preview-disclaimer{
  position: absolute;
  margin-top: 20%;
  margin-left: 2%;
  color:black;
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
}
.buttonsimp{
  margin-top: 7%;
}
.button-text{
  color: #000000!important;
  font-size: 13px!important;
}
@media screen and (min-width: 1367px){
.white-modal-file {
  left: 10.5%;
}
.guide-line-icon {
  left: 61vw;
}
.guide-line-text {
  left: 61.5vw;
}
.button-save-file {
  left: 52vw;
}
.all-rights-reserved-fileupload1 {
  margin-left: 10.5%;
}
.all-rights-reserved-fileupload {
  left: 86.5%;
}
}
.uploadfiles{
  font-size: 15px;
  color: #000000;
  font-weight: inherit;
  font-family: unset;
  margin-bottom : 2%;
  margin-top: 2.75%; 
  margin-left: 2%;
}
.uploadimage{
  margin-top: 2%;
  margin-left: 1.5%;
  display: grid;
  grid-template-columns: 180px 180px 180px 180px;
  column-gap: 70px;
  height: 200%;
  overflow-y: auto;
}
.filebuttons{
  margin-top: 30%;
  margin-bottom: 4%;
  display: flex;
  align-items: center;
}