.oval {
  position: absolute;
    top: 62%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 11%;
    width: 1.3%;
    border-radius: 10px;
    background-color: #F9BC91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval {
  width: 1.5% !important;
  top: 73% !important;
  left: 7% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.line-rectangle {
  position: absolute;
  top: 65%;
  bottom: 10%;
  left: 9.2%;
  right: 8%;
  height: 4%;
  width: 86%;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.line-rectangle {
    top: 77%;
    left: 8%;
    width: 83% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.line-rectangle {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
}
.rectangle1 {
  position: absolute;
    top: 65%;
    left: 8%;
    height: 4px;
    width: 14.5%;
    background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.rectangle1 {
    top: 77%;
    width: 14%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.rectangle1 {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 16%;
    width: 2%;
}
}
.oval-copy {
  position: absolute;
  top: 62%;
  bottom: 10%;
  left: 22.16%;
  right: 20%;
  height: 11%;
  width: 1.3%;
  background-color: #f9bc91;
  border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy {
    width: 1.5%;
    top: 73%;
    left: 20.83%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy {
    top: 20%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.rectangle2 {
  position: absolute;
    top: 65%;
    left: 23%;
    height: 4px;
    width: 17%;
    background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px){
.rectangle2 {
    top: 77%;
    left: 22%;
    width: 13%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.rectangle2 {
    top: 20%;
    left: 20%;
    right: 73px;
    height: 17%;
    width: 2%;
}
}
.oval-copy1 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color: #f9bc91;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy1 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy1 {
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.all-rights-reserved-company1{
  z-index: 1;
  margin-top: 45.1%;
  margin-left: 8.5%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size );
 }
.all-rights-reserved-company {
  margin-top: 45%;
  /* margin-left: 5%; */
  margin-left: 13.2%;
  z-index: 1;
  height: 14px;
  width: 100px;
  color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.button-save-comp {
  background-color: Transparent;
  cursor: pointer;
  box-sizing: border-box;
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
  margin-right: 2%;
}
.company-save {
  text-align: center;
  justify-content: center;
  height: 14px;
  width: 28px;
  color: #3b3535;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
}
.button-continue-comp {
  border: none;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
  color: #ffffff;
}
.company-continue {
  color: #ffffff;
}
.oval-copy2 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 54%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #EEF1F2
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy2 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 48.5%;
    right: 507px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy2 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-copy3 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 67%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy3 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 62.3%;
    right: 365px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy3 {
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-copy4 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 80%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy4 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy4 {
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval-copy5 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 93%;
    right: 10%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval-copy5 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval-copy5 {
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.group-information {
  position: absolute;
    top: 40%;
    bottom: 10%;
    left: 3.5%;
    right: 42px;
    height: 10%;
    width: 83%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.group-information {
    position: absolute;
    top: 50%;
    bottom: 344px;
    left: 3%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (min-width: 1201px){
.all-rights-reserved-company {
  /* margin-left:25% !important; */
}}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.group-information {
    top: 5% !important;
    left: 30% !important;
}
}
.product-information1 {
  position: absolute;
    top: 40%;
    left: 18.5%;
    right: 10%;
    height: 10%;
    width: 80%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.product-information1 {
    position: absolute;
    top: 50%;
    left: 16%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.product-information1 {
    top: 20%;
    left: 30%;
}
}
.company-info1 {
  position: absolute;
    top: 40%;
    left: 34.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px){
.info-icon-company1{
  margin-left: 45% !important;
}
.info-icon-company2{
  margin-left: 59% !important;
}
.company-info1 {
  position: absolute;
  top: 50%;
  left: 30%;
  right: 42px;
  height: 14px;
  width: 20%;
  color:var(--main-txt-color) ;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.company-info1 {
    top: 35%;
    left: 30%;
}
}
.dimensions {
  position: absolute;
  top: 40% ;
  bottom: 30%;
  left: 52%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.item {
  position: absolute;
  top: 40% ;
  bottom: 30%;
  left: 64.5%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.additional-company {
  position: absolute;
  top: 40%;
  left: 76.5%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.additional-company {
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.additional-company {
    top: 80% !important;
    left: 30% !important;
}
}
.file {
  position: absolute;
    top: 40%;
    left: 91%;
    height: 10%;
    width: 95%;
    right: 10%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
/* .company-back {
  margin-bottom: 2%;
  margin-left: 3%;
  margin-top: 5.5%;
} */
.company-button {
  width: 32%;
    margin-top: 2%;
    margin-left: 66%;
}
.rectangle3 {
  box-sizing: border-box;
  margin-top: 3%;
  height: 34px;
  width: 217px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
  }
.rectangl3 {
  box-sizing: border-box;
  margin-top: 3%;
  height: 34px;
  width: 217px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.companyArea {
  box-sizing: border-box;
  height: 63px;
  width: 220px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.companyArea1 {
  box-sizing: border-box;
  height: 63px;
  width: 220px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.company-information {
  margin-left: 3.5%;
  margin-top: 5%;
  font-size: 16px;
  }
.product-box-product1 {
  margin-top: 3%;
  box-sizing: border-box;
  width: 220px;
  height: 34px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.select-box {
  margin-top: 3%;
  box-sizing: border-box;
  width: 220px !important;
  border: 0px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.css-yk16xz-control { 
  border-radius:0px !important;
  min-height:25px !important
}
.css-tlfecz-indicatorContainer 
{
  padding :4px !important;
}
.css-1pahdxg-control
{
  
  width: 100% !important;
  border-color:solid #49a7a2 !important;
  
}
@media screen and (max-width: 991px) and (min-width: 601px){
   .css-1pahdxg-control{
    left: -21% !important;
    width: 100% !important;
   }
  .css-yk16xz-control{
    left: -21% !important;
    width: 100% !important;
  }
  .all-rights-reserved-company1{
    z-index: 1;
    margin-top: 46%;
    width: 30%;
    margin-left: 4%;
    color: #77FCFB;
   font-family: var(--main-font-family);
    font-size: var( --disclaimer-font-size );
   }
  .all-rights-reserved-company {
    margin-top: 47%;
    margin-left: 13%;
    z-index: 1;
    height: 14px;
    width: 100px;
    color: #77FCFB;
    font-family: var(--main-font-family);
    font-size: var(--disclaimer-font-size);
    letter-spacing: 0;
    line-height: 14px;
  }
  .info-icon-company1 {
    position: absolute;
    top: 0%;
    margin-left: 42% !important;
    height: 14%;
    width: 14%;
   }
   .info-icon-company2 {
    position: absolute;
    top: 0%;
    margin-left: 55% !important;
    height: 14%;
    width: 14%;
   }
}

.headfix2 {
  margin-left: 115px;
  margin-top: 163px;
  position: absolute;
  height: 102px;
  background: #ffffff;
  width: 1130px;
  z-index: 1;
}
.company-box {
  height: 80px;
   margin-top: 2%;
   margin-left: -2%;
 }
 .company-box1 {
   margin-left: 4%;

 }
 .delete{
  margin-top: -15%;
  margin-left: 93%;
}
.delete1{
  margin-top: -10%;
    margin-left: 93%;
}
.info-icon-company{
  position: absolute;
  top: 16%;
  margin-left: 20%;
  height: 14%;
  width: 14%;
}
.confirmmsg-company{
  color:red;
  margin-top: -10%;
  margin-left: 28%;
}
.plus-item-company{
  margin-top: -10%;
  margin-left: 92%;
}
.comp-information{
  margin-left: 3.5%;
  margin-top: 3%;
  font-size: 16px;
  margin-bottom: 2%;
}
.errormsg{
  color:red;
  margin-top: 2%;
}
.errormsgnum{
  color:red;
  margin-top: 5%;
}
.error-text{
  color: red;
  margin-top: 4%;
}
.error-text-aria{
  margin-top: 13%;
  color: red;
}
.company-back {
 margin-bottom: 1%;
  margin-left: 3%;
  margin-top: 9.5%;
}
@media screen and (max-width: 992px) and (min-width: 601px)
{
.company-back {
    margin-top: 12%;
    margin-bottom: 2%;
}
.company-box1 {
  margin-left: 4%;
  gap: 25px;
  
}
}
.man-plus-1 {
  margin-top: -20%;
  margin-left: 93%;
}
.white-rectangle-company {
  position: absolute;
    /* top: 34%; */
    top: 30%;
    left: 8.46%;
    /* height: 42%; */
    height: 48%;
    width: 83.09%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--main-bg-color);
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.white-rectangle-company {
    position: absolute;
    /* top: 21%; */
    top:17.2%;
    left: 1.5%;
    height: 56%;
    width: 70%;
}
}
@media screen and (max-width: 992px) and (min-width: 601px)
{
.white-rectangle-company {
 /* top: 21%; */
  top:17.2%;
  left: 4%;
  height: 60%;
  width: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--main-bg-color);
}
.info-icon-company {
  top: 1% !important;
  margin-left: 84% !important;
 }
.delete{
  margin-top: -17%;
  margin-left: 80%;
}
.delete1{
  margin-top: -9%;
  margin-left: 80%;
}
.man-plus-1 {
  margin-top: -17%;
  margin-left: 81%;
}
.plus-item-company{
margin-top: -9%;
margin-left: 80%;
}
.confirmmsg-company{
  margin-top: -14%;

}
.comp-box1 {
  height: auto;
  margin-left: -2%;
  margin-top: 2%;
  margin-bottom: 3%;
}
.companyArea{
  margin-top: 3%;
  margin-bottom: 2%;
}
.companyArea1{
  margin-top: 3%;
  margin-bottom: 2%;
}

}

.comp-box1 {
  height: auto;
  margin-left: -2%;
  margin-top: 2%;
}
.company-box2 {
  height: auto;
  margin-left: -2%;
  margin-top: 8%;
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .item-button {
    margin-top: -3%;
    width: 50%;
    margin-left: 60%;
    margin-bottom: 2%;
  }
  .comp-information{
    margin-left: 3.5%;
    margin-top: 16%;
    font-size: 16px;
  }
  .company-box1{
    width :101% !important ;
  }
  .delete{
    margin-top: -15%;
    margin-left: 85%;
  }
  .delete1{
    margin-top: -10%;
      margin-left: 85%;
  }
  .man-plus-1 {
    margin-top: -20%;
    margin-left: 85%;
  }
 .plus-item-company{
  margin-top: -9%;
  margin-left: 80%;
 }
 .info-icon-company {
  position: absolute;
  margin-top:-6%;
  margin-left: 89%;
 }
}
.info-icon-company1 {
  position: absolute;
  top: 0%;
  margin-left: 51%;
  height: 14%;
  width: 14%;
 }
 .info-icon-company2 {
  position: absolute;
  top: 0%;
  margin-left: 67%;
  height: 14%;
  width: 14%;
 }
 @media screen and (min-width: 1367px){
  .white-rectangle-company{
    left: 10.5%;
  }
  .all-rights-reserved-company1{
      margin-left: 11%;
    }
  .all-rights-reserved-company{
    margin-left: 24% !important
    }
 }

