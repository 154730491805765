.logo{
  position: absolute;
  left: 162px;
  top: 36px;
  height: 45px;
  width: 136px;
}
.back{
width: 100%;
background-size: cover;
height : max-content; 
background-attachment : fixed; 
background-position : center;
position: absolute;
}
              
@media screen and (max-width: 980px){
.back{ 
  position: absolute;
  height:980px;
}

}
@media screen and (min-width:780px) and (max-width: 980px){

  .back{ 
    background: url('../../../common/bg_tablet.jpg'); 
    position: absolute;
    height: 1200px !important;
  }
}

@media screen and (max-width: 501px){
.back{ 
  position: absolute;
  height: 780px;
}

}
@media screen and (max-width: 435px){
.back{ 
  position: absolute;
  height: 650px;
}
}
@media screen and (min-width: 1367px)
{
.back{
  width: 105%;
}
}
