.filter {
  background: #ebebeb;
  width: 35%;
  padding: 20px;
  padding-top: 20px;
  z-index: 100;
  padding-bottom: 0;
}

.filter__header {
  font-weight: 600;
  font-size: 1.1rem;
}

.filter__main {
  overflow-y: auto;
  height: calc(80% - 40px);
  margin-bottom: 10px;
}

.filter__footer {
  position: sticky;
  bottom: 0;
  height: calc(20%);
  margin: -20px;
  background: #ebebeb !important;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.button-continue-group {
  position: absolute;
  bottom: 10%;
  border: none;
  left: 82%;
  height: 35px;
  width: 150px;
  border-radius: 19px;
  background-color: #f28f7b;
  position: relative;
  left: 0;
  bottom: 0;
  float: right;
  /* margin: auto; */
  align-self: center;
}
.button-continue-group:hover {
  background-color: #b67569;
}

.button-revert-group {
  position: absolute;
  bottom: 10%;
  border: none;
  left: 82%;
  height: 35px;
  width: 150px;
  border-radius: 15px;
  background-color: transparent;
  position: relative;
  left: 0;
  bottom: 0;
  float: right;
  /* margin: auto; */
  align-self: center;
}

.button-revert-group:hover {
  background-color: #e6e3e3d0;
}
