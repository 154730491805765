:root {  
  --main-font-family : Mercedes!important;
  --main-font-family-regular : Mercedes Regular!important;
  --main-font-family-bold : Mercedes Bold !important;
  --main-txt-color:#000000; 
  --main-bg-color: #FFFFFF; 
  --base-font-size: 13px;
  --disclaimer-font-size: 0.80rem;
 }
 .App, .Count {
  text-align: center;
  border    : 1px solid #ccc;
  padding   : 15px;
  margin    : 15px;
}
*{
  outline: none;
}

@font-face {
  font-family: Mercedes;
  src: url(./mercedes-fonts/films.meresre.ttf);

}
@font-face {
  font-family: Mercedes Bold;
  src: url(./mercedes-fonts/mercedes\ bold.ttf);
}
@font-face {
  font-family: Mercedes Regular;
  src: url(./mercedes-fonts/films.meresre.ttf);
}

input:focus{
  outline-color: #51A7A4;
}

select:focus{
  outline-color :#51A7A4;
}
button:disabled {
  cursor: not-allowed;
}


.scrollable::-webkit-scrollbar {
  width: 3px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

.scrollable::-webkit-scrollbar-track {
  /* margin-left: 5px; */

  /* box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); */
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 1px;
  /* margin-left: 5px; */
  /* outline: 1px solid slategrey; */
}
/* .scrollable::-webkit-scrollbar {
      width: 1px;
      height: 40px;
  } */

.scrollable {
  -ms-overflow-style: auto;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overscroll-behavior: auto contain;
  overflow-y: auto;
}

.disabled {
  background-color: #f2f2f2;
  color: gray;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.input-disabled {
  /* color:#ccc; */
  background-color: #f2f2f2;
}