* {
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var(--base-font-size);
}
.oval {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 14%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #F9BC91;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.straight-line1 {
  position: absolute;
    top: 65%;
    left: 8.5%;
    height: 4px;
    width: 14.5%;
    background-color: #f9bc91;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.straight-line1 {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 15%;
    width: 2%;
}
}
.oval1 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 22.16%;
  right: 20%;
  height: 14%;
  width: 1.2%;
  background-color:#f9bc91;
  border-radius: 10px;
}
@media screen and (max-width: 991px) and (min-width: 601px){
  .oval1 {
      top: 20% !important;
      left: 18% !important;
      height: 12px !important;
      width: 12px !important;
  }
  }
.oval2 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color: #EEF1F2;
    border-radius: 10px;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval2 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval3 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    background-color: #EEF1F2;
    border-radius: 10px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval3 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval3 {
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval4 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 54%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval4 {
  position: absolute;
  top: 73%;
  bottom: 317px;
  left: 48.5%;
  right: 507px;
  height: 14px;
  width: 1.5%;
  border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval4 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval5 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 67%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval5 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 62.3%;
    right: 365px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval5 {
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval6 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 80%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval6 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval6 {
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.oval7 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 93%;
    right: 10%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #EEF1F2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.oval7{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.oval7 {
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.gray-line {
  position: absolute;
    top: 65%;
    bottom: 10%;
    left: 8%;
    right: 8%;
    height: 4%;
    width: 86%;
    background-color: #EEF1F2;
  
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.gray-line {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
}
.group-information {
  position: absolute;
    top: 40%;
    bottom: 10%;
    left: 3.5%;
    right: 42px;
    height: 10%;
    width: 83%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.group-information {
  top: 5% !important;
  left: 30% !important;
}
}
.product-information {
  position: absolute;
  top: 40%;
  left: 18.5%;
  right: 10%;
  height: 10%;
  width: 80%;
  color:var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.product-information {
    position: absolute;
    top: 50%;
    left: 16%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.product-information {
  top: 19% !important;
  left: 30% !important;
}
}
.company-info {
  position: absolute;
    top: 40%;
    left: 34.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.company-info {
    position: absolute;
    top: 50%;
    left: 30%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.company-info {
  top: 34% !important;
  left: 30% !important;
}
}
.dimensions-information {
  position: absolute;
  top: 40%;
  left: 51.5%;
  height: 14px;
  width: 20%;
  color: #b7b7b7;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.dimensions-information {
  
    top: 50% !important;
    left: 46% !important;
    
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.dimensions-information {
  top: 49% !important;
  left: 30% !important;
}
}
.item-information {
  position: absolute;
  top: 40%;
  left: 65%;
  height: 14px;
  width: 20%;
  color: #b7b7b7;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.item-information {
    top: 50% !important;
    left: 60% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.item-information {
  top: 64% !important;
  width: 60% !important;
  left: 30% !important;
}
}
.additional-information {
  position: absolute;
    top: 40%;
    left: 76.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.additional-information {
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.additional-information {
  top: 79% !important;
  left: 30% !important;
}
}
.file-information {
  position: absolute;
  top: 37%;
  left: 91%;
  height: 10%;
  width: 95%;
  right: 10%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.file-information {
  top: 93% !important;
  left: 30% !important;
}
}
.asteriskproduct{
  color:red;
}
.all-rights-reserved-product1{

  z-index: 1;
  margin-top: 44%;
  margin-left: 8.4%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size);
 }
.all-rights-reserved-product {
  position: absolute;
    top: 78%;
    /* left: 84%; */
    left:85.9%;
    height: 14px;
    width: 20%;
    color: #77FCFB;
    font-family: var(--main-font-family-regular);
    font-size: var(--disclaimer-font-size);
    letter-spacing: 0;
    line-height: 14px; 
}

@media screen and (max-width: 1199px) and (min-width: 992px){
.all-rights-reserved-product {
  left: 65%;
  top: 78%
}
.all-rights-reserved-product1{
  left: 4%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px){

  .all-rights-reserved-product {
      top: 84% !important;
      left: 47% !important;
      height: 14px;
      width: 20%;
      color: #77FCFB;
      font-family: var(--main-font-family-regular);
      font-size: var(--disclaimer-font-size);
      letter-spacing: 0;
      line-height: 14px; 
  }
.all-rights-reserved-product1{
  width: 30%;
  z-index: 1;
  margin-top: 46%;
  margin-left: 4%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
 }
}
::placeholder {
  position: absolute;
  top: 10px;
  left: 11px;
  right: 81px;
  height: 14px;
  width: 17px;
  color: #676767;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.product-button{
  width: 32%;
  margin-left: 60%;
}
@media screen and (max-width: 1199px) and (min-width: 992px){
  .product-button {
    width: 50%;
    margin-left: 60%;
    margin-top: -2%;
}
  }
  @media screen and (max-width: 991px) and (min-width: 601px){
.product-button {
  margin-left: 45%;
  width: 110%;
  margin-top: -5%;
}
  }
.button-save-product{
  background-color: Transparent;
  cursor: pointer;
  box-sizing: border-box;
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
  margin-right: 2%;
}
.product-continue{
  color: #ffffff;
}
.button-continue-product{
  border: none;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
  color: #ffffff;
}
.st-line-product{
  margin-top: 4.5% !important;
  margin-left: 3.3%;
  height: 1px;
  width: 1048px;
  border-bottom: 1px solid #d8d8d8;
}
@media screen and (min-width: 992px) and (max-width: 1199px){
.st-line-product{
  width: 90%;
}}
.info-icon{
  position: absolute;
  margin-top: -22%;
  margin-left: 45%;
}
.info-icon-hsn{
  position: absolute;
  margin-top: -22%;
  margin-left: 27%;
}


@media screen and (min-width: 992px) and (max-width: 1199px){
  .info-icon{
    margin-top: -24%;
    margin-left: 52%;
  }
  .info-icon-hsn{
    margin-top: -24%;
    margin-left: 52%;
  }
}
@media screen and (min-width: 601px) and (max-width: 992px){
  .info-icon{
    margin-top: -19%;
    margin-left: 38%;
  }
  .info-icon-hsn{
    margin-top: -19%;
    margin-left: 22%;
  }
  .info-icon1{
    margin-top: -19% !important;
    margin-left: 34% !important;
  }
}
.info-icon1{
  position: absolute;
  margin-top: -22%;
  margin-left: 40%;
}
.keyicon{
  position: absolute;
  margin-left: 38%;
  margin-top: -34%;
}
@media screen and (min-width: 601px) and (max-width: 992px){
  .keyicon{
    margin-top: -30%;
    margin-left: 31%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px){
  .product-back{
    margin-left: 3% !important;
    margin-top: 3%;
    margin-bottom: 2%;
  }
  .info-icon1{
    margin-top: -24%;
    margin-left: 48%;
  }
  .info-icon2{
    margin-top: -25% !important;
    margin-left: 45% !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 992px){
  .info-icon2{
  position: absolute;
  margin-top: -19% !important;
  margin-left: 46% !important;
  height: 14%;
  width: 14%;
}
} 
@media screen and (min-width: 601px) and (max-width: 991px){
  .st-line-product{
    width: 90%;
    margin-top: 5% !important;
  }
  .confirmmsg-product{
    margin-top: -14% !important;
  }
}
.errormsg{
  margin-top: 2%;
}
.confirmmsg-product{
  color:red;
  margin-top: -10%;
  margin-left: 28%;
}
.companyArea{
  margin-top: 2%;
}
.item-box2 {
  height: auto;
  
  margin-left: -2%;
  margin-top: 2%;
}
.additional-back1{
  margin-bottom: 2%;
  margin-left: 3% !important;
  margin-top: 3%;
}

@media screen and (max-width: 991px) and (min-width: 601px){
  .css-1pahdxg-control{
  left: -12% !important;
  width: 100% !important;
  }
  .css-yk16xz-control{
  left: -1% !important;
  width: 100% !important;
  }
  .product-rectangle{
    width: 74% !important;
     }
  }

.product-rectangle{
  box-sizing: border-box;
  margin-top: 3%;
  height: 34px;
  width: 92%;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
  }
  @media screen and (max-width: 1199px) and (min-width: 992px){
    .product-rectangle{
      width: 102% !important;
       }
    }
  @media screen and (max-width: 991px) and (min-width: 601px){
    .product-back {
        margin-top: 5%;
        margin-left: 3%;
        margin-bottom: 2%;
    }
    }
    .product-back {
      margin-top: 8%;
      margin-left: 3%;
      margin-bottom: 2%;
  }
  .info-icon2{
    position: absolute;
    margin-top: -22%;
    margin-left: 55%;
    height: 14%;
    width: 14%;
  }
  @media screen and (min-width: 1367px){
    .all-rights-reserved-product1{
      margin-left: 11%;
    }
    .all-rights-reserved-product{
      left: 86% !important;
    }
  }