.gray-rectangle-item{
    position: absolute;
    height: 6.25%;
    /* top:14.97%; */
    top:11%;
    left: 8.46%;
    width: 83.09%;
    background-color: rgba(238,241,242,0.83);
  }
  .alphabh{
    font-size: 15px !important;
    font-family: var(--main-font-family-regular);
    
  }
  .omega{
    display: flex !important;
    flex-direction: row !important;
    margin-top: 20px !important;
  }
  .header{
    font-family: var(--main-font-family-bold);
    font-size: 25px;
    margin-top: -0px !important;
    margin-left: 60px;
  }
  .headerDiv{
    height: 40px;
    margin-top: 65px !important;
  }
  .border{
    height: 2px;
    background-color: #49a7a2;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 7px;
  }
  .buttondiv-logout-warning{
    position: relative !important;
    border: none !important;
    left: 22%;
    height: 34px !important;
    width: 120px !important;
    border-radius: 19px !important;
    background-color:#F28F7B !important;
    color: white;
  }
  .buttondiv-logout-warning1{
    position: relative !important;
    border-width: 1px !important;
    left: 16%;
    height: 34px !important;
    width: 120px !important;
    border-radius: 19px !important;
    background-color:white !important;
    border-color: #49A7A2 !important;
    color: black;
    font-size: 13px !important;
  }
.form-upload-item{
  position: absolute;
    top: 30%;
    left: 6.5%;
    height: 10%;
    width: 100%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
}
.image{
margin-left: 115px !important;
}
.excel-upload-item{
  position: absolute;
    top: 30%;
    left: 23%;
    height: 10%;
    width: 10%;
    color: #414141;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
    cursor: not-allowed;
}
.green-rectangle-item{
  position: absolute;
  top: 88%;
  left: 47px;
  height: 4px;
  width: 126px;
  background-color: #49A7A2;
}
.line-item{
  position: absolute;
  top: 13px;
  right: 375px;
  box-sizing: border-box;
  height: 22px;
  width: 1px;
  border: 1px solid #D8D8D8;
}
.product-path-item{
  position: absolute;
    top: 32%;
    left: 67.5%;
    height: 10%;
    width: 20%;
    color: #5BBDBC;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
.bread-crumb-item{
  position: absolute;
  top: 1px;
  left: 79px;
  height: 6px;
  width: 10px;
  color: #8C8A8A;
}
.digital-item{
  position: absolute;
    top: 32%;
    left: 76%;
    height: 10%;
    color: #424242;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
.menu{
  position: absolute;
      top: 30%;
      left: 94%;
      height: 35%;
      width: 4%;    
}
.menu-box{
position: absolute;
top: 30%;
left: 90%;
background-color: greenyellow;
height: 130%;
width: 8%;

}
@media screen and (min-width: 1200px){
  .gray-rectangle-item{
    /* top: 14.97%; */
    top:11%;
    left: 8.46%;
    width: 83.09%;
      }
  .menu{
      display: none;
  }
  }

@media screen and (min-width: 992px) and (max-width: 1199px){
  .gray-rectangle-item{
      left: 1.5%;
      width: 70%;
      }
      .product-path-item{
          left: 62%;    
      }
        .digital-item{
          left: 72%;
        }
        .green-rectangle-item{
          left: 3.5%;
        }
        
        .menu{
          display: none;
      }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .gray-rectangle-item{
      left: 4%;
      width: 50%;
      }
  .green-rectangle-item{
        top: 43px;
        left: 0%;
        width: 45%;
        background-color: #49A7A2;
    } 
  .form-upload-item {
      left: 17%;
    }
  .excel-upload-item {
      left: 60%;
    }
    .product-path-item{
      display: none;    
  }
    .digital-item{
      display: none;
    }
    } 
  
@media screen and (min-width: 601px) and (max-width: 767px){
  .gray-rectangle-item{
      left: 4% !important;
      width: 50% !important;
      }
  .green-rectangle-item{
        top: 43px;
        left: 0%;
        width: 42%;
        background-color: #49A7A2;
    } 
  .form-upload-item {
      left: 17%;
    }
  .excel-upload-item {
      left: 60%;
    }
    .product-path-item{
      display: none;    
  }
    .digital-item{
      display: none;
    }
}
@media screen and (min-width: 400px) and (max-width: 600px){
  .gray-rectangle-item{
      top:12%;
      left: 0.5%;
      width: 43%;
      }
   .green-rectangle-item{
        top: 43px;
        left: 0%;
        width: 40%;
        background-color: #49A7A2;
    } 
}
@media screen and (max-width: 399px){
  .gray-rectangle-item{
      position: absolute;
      height: 48px;
      top:10%;
      left: 7px;
      width: 360px;
      background-color: rgba(238,241,242,0.83);
      }
  .form-upload-item{
      position: absolute;
      top: 18px;
      left: 10%;
      height: 14px;
      width: 73px;
      color: var(--main-txt-color);
      font-family: var(--main-font-family);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 14px;
  }
  .excel-upload-item{
      position: absolute;
      top: 21px;
      left: 55%;
      height: 14px;
      width: 69px;
      color: #414141;
      font-family: var(--main-font-family);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      cursor: not-allowed;
  }   
  .green-rectangle-item{
      position: absolute;
      top: 43px;
      left: 0%;
      height: 4px;
      width: 42%;
      background-color: #49A7A2;
  } 
}
@media screen and (min-width: 1367px)
{
.gray-rectangle-item {
    /* top: 14.97%; */
    top:11%;
    left: 10.5%;
    width: 83.09%;
}

}