.filter-group__header {
  font-size: 1.07rem;
  font-weight: 600;
  color: #7a7a7a;
  margin-bottom: 10px;
  margin-right: 10px;
}

.filter-group__header__skeleton {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 10px;
}

.filter__container {
  margin-bottom: 20px;
}

.filter__main_label {
  margin-bottom: 10px;
  font-size: 1.2rem;
}
