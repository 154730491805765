.white-modal-item {
    position: absolute;
    /* top: 33%; */
    top: 30%;
    bottom: 89px;
    left: 8.46%;
    height: 330px;
    width: 83%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: var(--main-bg-color);
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .white-modal-item {
        left: 1.5%;
        width: 70%;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .white-modal-item {
        left: 4%;
        width: 50%;
        top: 21%;
        height: 60% !important;
    }
}

.gray-line-item {
    position: absolute;
    top: 65%;
    bottom: 10%;
    left: 8%;
    right: 8%;
    height: 4%;
    width: 86%;
    background-color: #eef1f2;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .gray-line-item {
        top: 77%;
        left: 8%;
        width: 83% !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .gray-line-item {
        top: 5%;
        left: 20%;
        right: 73px;
        height: 90%;
        width: 2%;
    }
}

.orange-line-item {
    position: absolute;
    top: 65%;
    left: 8%;
    height: 4%;
    width: 59%;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .orange-line-item {
        top: 77%;
        left: 8%;
        width: 55%;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .orange-line-item {
        top: 5%;
        left: 20%;
        right: 73px;
        height: 62%;
        width: 2%;
    }
}

.oval-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval-item {
        width: 1.5%;
        top: 73%;
        left: 7%;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval-item {
        top: 5%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.oval1-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 22.16%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval1-item {
        width: 1.5%;
        top: 73%;
        left: 20.83%;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval1-item {
        top: 20% !important;
        left: 18% !important;
        height: 12px !important;
        width: 12px !important;
    }
}

.oval2-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval2-item {
        position: absolute;
        top: 73%;
        bottom: 317px;
        left: 34.6%;
        right: 675px;
        height: 14px;
        width: 1.5%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval2-item {
        top: 35%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.oval3-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 54%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval3-item {
        position: absolute;
        top: 73%;
        bottom: 317px;
        left: 48.5%;
        right: 507px;
        height: 14px;
        width: 1.5%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval3-item {
        top: 50%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.oval4-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 67%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #f9bc91;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval4-item {
        position: absolute;
        top: 73%;
        bottom: 317px;
        left: 62.3%;
        right: 365px;
        height: 14px;
        width: 1.5%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval4-item {
        top: 65%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.oval5-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 80%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #eef1f2;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval5-item {
        position: absolute;
        top: 73%;
        bottom: 317px;
        left: 76.15%;
        right: 215px;
        height: 14px;
        width: 1.5%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval5-item {
        top: 80%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.oval6-item {
    position: absolute;
    top: 61%;
    bottom: 10%;
    left: 93%;
    right: 10%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #eef1f2;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .oval6-item {
        position: absolute;
        top: 73%;
        bottom: 317px;
        left: 90%;
        right: 64px;
        height: 14px;
        width: 1.5%;
        border-radius: 10px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .oval6-item {
        top: 94%;
        left: 18%;
        height: 12px;
        width: 12px;
    }
}

.company-info-item {
    position: absolute;
    top: 40%;
    left: 34.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var(--base-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .company-info-item {
        position: absolute;
        top: 50%;
        left: 30%;
        right: 42px;
        height: 14px;
        width: 20%;
        font-family: var(--main-font-family);
        font-size: var( --base-font-size);
        letter-spacing: 0;
        line-height: 14px;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .company-info-item {
        top: 34% !important;
        left: 30% !important;
    }
}

.dimensions-item {
    position: absolute;
    top: 40%;
    bottom: 30%;
    left: 52%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var(--base-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .dimensions-item {
        top: 50% !important;
        left: 46% !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .dimensions-item {
        top: 49.5% !important;
        left: 30% !important;
    }
}

.item-item {
    position: absolute;
    top: 40%;
    bottom: 30%;
    left: 64.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var(--base-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .item-item {
        top: 50% !important;
        left: 60% !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .item-item {
        top: 64% !important;
        width: 60% !important;
        left: 30% !important;
    }
}

.st-line-item {
    margin-top: 6% !important;
    margin-left: 3.3%;
    height: 1px;
    width: 93%;
    border-bottom: 1px solid #d8d8d8;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .st-line-item {
        width: 93% !important;
    }
    .plus-item {
        margin-left: 107% !important;
        margin-top: -12%;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .st-line-item {
        width: 93% !important;
    }
}

.button-save-item {
    position: absolute;
    background-color: Transparent;
    cursor: pointer;
    overflow: hidden;
    top: 2175px;
    left: 771px;
    margin-bottom: 4%;
    box-sizing: border-box;
    height: 34px;
    width: 151px;
    border: 1px solid #49a7a2;
    border-radius: 18px;
}

.save-item {
    position: absolute;
    top: 10px;
    left: 61px;
    height: 14px;
    width: 28px;
    color: #3b3535;
    font-family: var(--main-font-family);
    font-size: var(--base-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

.button-continue-item {
    position: absolute;
    border: none;
    cursor: pointer;
    overflow: hidden;
    top: 2175px;
    left: 941px;
    margin-bottom: 4%;
    height: 34px;
    width: 151px;
    border-radius: 19px;
    background-color: #f28f7b;
}

.continue-item {
    position: absolute;
    top: 10px;
    left: 51px;
    height: 14px;
    width: 49px;
    color: #ffffff;
    font-family: var(--main-font-family);
    font-size: var(--base-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

.info-item {
    margin-left: 35%;
    margin-top: -7%;
}

.cam-back-it {
    position: absolute;
    left: 39px;
    top: 2166px;
    margin-bottom: 4%;
    height: 40px;
    width: 44px;
    cursor: pointer;
}

.in-the-box {
    width: 100% !important;
    margin-bottom: 5%;
}
.plus-item {
    margin-top: -11% !important;
    margin-left: 94% !important;
    z-index: 1;
}
.AddData{
    position: relative;
    margin-top: -11%;
    margin-left: 94%;
    z-index: 1; 
}
.delete-item {
    position: relative;
    margin-top: -10% !important;
    margin-left: 94% !important;
    z-index: 1;
}
.deleteData {
    position: relative;
    margin-top: -11%;
    margin-left: 94%;
    z-index: 1;
}
.plus-item-Qty {
    margin-left: 34%;
    margin-top: -14%;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .plus-item-Qty {
        margin-left: 40%;
        margin-top: -15%;
    }
}

@media screen and (max-width: 992px) and (min-width: 601px) {
    .delete-item {
        margin-top: -8% !important;
        margin-left: 80% !important;
    }
    .plus-item-Qty {
        margin-left: 30%;
        margin-top: -16%;
    }
    .plus-item {
        margin-left: 80% !important;
        margin-top: -8% !important;
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .all-rights-reserved-item {
        left: 65%;
        top: 80%
    }
}

.memory-radio-item {
    box-sizing: border-box;
    height: 12px;
    width: 12px;
    border-radius: 10px;
    border: 1px solid #979797;
    background-color: #f2f3f5;
    margin-left: 7%;
    margin-top: 4%;
    margin-right: 3%;
}

.header-fix4 {
    margin-left: 8.46%;
    /* margin-top: 12%; */
    margin-top: 9.72%;
    position: absolute;
    /* height: 16%; */
    height: 13.5%;
    background: #ffffff;
    width: 83.09%;
    z-index: 1;
}

@media screen and (min-width: 1200px) {
    .header-fix4 {
        display: block !important;
    }
    .delete-img1 {
        margin-top: -11% !important;
    }
}

.item-box1 {
    height: auto;
    margin-left: -2%;
    margin-top: 2%;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .item-box {
        margin-left: -3% !important;
        position: relative !important;
        left: 7% !important;
    }
}

.item-information1 {
    margin-left: 3.5%;
    margin-top: 3%;
    font-size: 16px;
}

.item-back {
    margin-bottom: 2%;
    margin-left: 3%;
    margin-top: 9.5%;
}

.qty-item-box {
    margin-top: 3%;
    margin-left: -10%;
    box-sizing: border-box;
    height: 34px;
    width: 74px;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .qty-item-box {
        margin-left: 1%;
    }
}

.item-save-comp {
    background-color: Transparent;
    cursor: pointer;
    box-sizing: border-box;
    height: 34px;
    width: 151px;
    border: 1px solid #49a7a2;
    border-radius: 18px;
    margin-right: 2%;
}

.space {
    margin-top: 7.5%;
}

.item-button {
    width: 32%;
    margin-left: 60%;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .item-button {
        margin-top: -3%;
        width: 50%;
        margin-left: 55%;
    }
}

@media screen and (max-width: 992px) and (min-width: 601px) {
    .AddData{
        margin-top: -9%;
        margin-left: 79%;
    }
    .item-button {
        margin-top: -4%;
        width: 60%;
        margin-left: 45%;
    }
    .all-rights-reserved-item {
        top: 82% !important;
        left: 47% !important;
    }
    .all-rights-reserved-item1 {
        z-index: 1;
        margin-top: 46.1%!important;
        margin-left: 4% !important;
        color: #77FCFB;
        font-family: var(--main-font-family);
        font-size: var( --disclaimer-font-size);
    }
}

.item-box {
    margin-left: 4%;
}

input[type="checkbox"],
input[type="radio"] {
    margin-top: 7%;
    margin-left: 5%;
}

.radiolabel {
    margin-left: 3%;
}

.rectangle4 {
    box-sizing: border-box;
    margin-top: 11%;
    height: 34px;
    width: 217px;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}

.hiddenBox {
    box-sizing: border-box;
    margin-top: 2%;
    height: 34px;
    width: 74px;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color)
}

.arrow-icon-item {
    margin-top: -12%;
    margin-left: 90%;
}

.item-rectangle {
    margin-top: 11%;
    box-sizing: border-box;
    height: 34px;
    width: 74px;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}

.all-rights-reserved-item {
    position: absolute;
    top: 78%;
    left: 84%;
    height: 14px;
    width: 20%;
    color: #77FCFB;
    font-family: var(--main-font-family-regular);
    font-size: var(--disclaimer-font-size);
    letter-spacing: 0;
    line-height: 14px;
}

@media (min-width: 576px) {
    .col-sm-3 {
        flex: 1 0 25% !important;
        max-width: 26% !important;
    }
}

.confirmmsg-item {
    color: red;
    margin-top: -12%;
    margin-left: 28%;
}

.info-icon-item {
    margin-bottom: 15% !important;
    margin-top: -22%;
    margin-left: 73%;
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .info-icon-item {
        margin-left: 81% !important;
        margin-top: -23% !important;
    }
    .delete-img {
        margin-top: -13% !important;
    }
    .plus-img {
        margin-top: -17% !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 601px) {
    .delete-img {
        margin-top: 15% !important;
    }
    .delete-img1 {
        margin-top: 2% !important;
    }
    .info-icon-item {
        margin-left: 57% !important;
        margin-top: -19% !important;
    }
    .css-1pahdxg-control {
        left: 0% !important;
        width: 100% !important;
    }
    .plus-img {
        margin-top: 0% !important;
    }
}

@media screen and (min-width: 340px) and (max-width: 640px) {
    .white-modal-item {
        position: absolute;
        top: 16%;
        left: 0.59%;
        height: 487px;
        width: 25.4%;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: var(--main-bg-color);
        right: 74%;
    }
    .item-information1 {
        margin-left: 6%;
    }
    .select-box {
        width: 220px !important;
    }
    .companyArea {
        height: 33px;
        width: 300px;
    }
    .item-box1 {
        height: 58px;
        margin-left: -2%;
        margin-top: 5%;
    }
    .rectangle3 {
        width: 300px;
    }
    .inthebox {
        width: 200px !important;
    }
    .qty-item-box {
        margin-top: 3%;
        box-sizing: border-box;
        height: 34px;
        width: 74px;
        top: -56px;
        right: 51px;
        position: absolute;
        border: 1px solid #d8d8d8;
        background-color: var(--main-bg-color);
    }
    .qtyBox {
        position: absolute;
        top: -76px;
        right: 99px;
    }
    .plus-item-Qty {
        margin-left: 110%;
        margin-top: -22%;
    }
    .delete-item-Qty {
        margin-left: 110%;
        margin-top: -22%;
    }
    .company-box1 {
        margin-left: 2%;
        margin-bottom: 5%;
    }
    input[name='SG-InTheBox-OthersDetails'] {
        width: 200px
    }
}

.cam-box {
    box-sizing: border-box;
    margin-top: 2%;
    height: 34px;
    width: 217px;
    border: 1px solid lightpink;
    background-color: lightgrey;
}

.all-rights-reserved-item1 {
    z-index: 1;
    margin-top: 44%;
    margin-left: 8.4%;
    color: #77FCFB;
    font-family: var(--main-font-family);
    font-size: var( --disclaimer-font-size);
}

.delete-img {
    margin-top: 8%;
}

.plus-img {
    margin-top: -12%;
}

@media screen and (min-width: 1367px) {
    .white-modal-item {
        left: 10.5%!important;
    }
}