.feed__container {
  margin: 0;
  padding: 0;
  min-height: 200;
  font-feature-settings: inherit;
  justify-content: space-between;
}

.feed__header__label {
  font-weight: bold;
  font-family: inherit;
  color: #0f8686;
}
