

.rectangle{
  position: fixed !important;
}
.creation-continue{
  color: #ffffff;
}
.white-rectangle {
  position: absolute;
  top:34%;
  left: 8.46%;
  height: 42%;
  width: 83.09%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--main-bg-color);
}

.header-fix4 {
  margin-left: 8.46%;
  /* margin-top: 12%; */
  margin-top: 9.72%;
  position: absolute;
  /* height: 16%; */
  height: 13.5%;
  background: #ffffff;
  width: 83.09%;
  z-index: 1; 
} @media screen and (min-width: 991px){
    .header-fix4{
      display: block !important;
    }
}
.abc {
  color: #414141 !important;
  text-decoration: none;
}
.logoutHref{
  text-decoration: none;
  color: #FFFFFF;
}
.oval-creation {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 8%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
background-color: #F9BC91;
}
.line-creation{
  position: absolute;
    top: 65%;
    bottom: 10%;
    left: 8%;
    right: 8%;
    height: 4%;
    width: 86%;
  background-color: #EEF1F2;
}
.oval-creation1 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 22.16%;
  right: 20%;
  height: 14%;
  width: 1.2%;
background-color: #EEF1F2;
border-radius: 10px;
}
.oval-creation2{
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 39%;
  right: 20%;
  height: 14%;
  width: 1.2%;
  background-color: #EEF1F2;
  border-radius: 10px;
}
.group-information-creation{
  margin-top: 3%;
  margin-left: 3.5%;
  font-size: 16px;
}
.all-rights-reserved-group{
  position: absolute;
  top: 78% !important;
  /* left: 83%; */
  left:86%;
  height: 14px;
  width: 20%;
  color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.all-rights-reserved-group1{
  z-index: 1;
  margin-top: 43.8%;
  margin-left: 8.5%;
  color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size );
 }


.oval-creation3 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 54%;
  right: 20%;
  height: 14%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
.oval-creation4 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 67%;
  right: 20%;
  height: 14%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
.oval-creation5 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 80%;
  right: 20%;
  height: 14%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #EEF1F2;
}
.oval-creation6 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 93%;
  right: 10%;
  height: 14%;
  width: 1.2%;
border-radius: 10px;
background-color: #EEF1F2;
}
.group-information2{
  position: absolute;
  top: 40%;
  bottom: 10%;
  left: 3.5%;
  right: 42px;
  height: 10%;
  width: 83%;
  color: var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.product-information-creation{
  position: absolute;
    top: 40%;
    left: 18.5%;
    right: 10%;
    height: 10%;
    width: 80%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.creation-button {
  width: 32%;
  margin-left: 60%;

}
.company-creation {
  position: absolute;
    top: 40%;
    left: 34.5%;
    right: 10%;
    height: 10%;
    width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.dimensions{
  position: absolute;
  top: 40% !important;
  bottom: 30%;
  left: 52%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.item{
  position: absolute;
  top:  40% !important;
  bottom: 30%;
  left: 64.5%;
  right: 10%;
  height: 10%;
  width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.additional-creation{
  position: absolute;
    top: 40%;
    left: 76.5%;
    right: 10%;
    height: 10%;
    width: 95%;
  color: #B7B7B7;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.file{
  position: absolute;
  top: 40%;
  left: 91%;
  height: 10%;
  width: 95%;
  right: 10%;
color: #B7B7B7;
font-family: var(--main-font-family);
font-size: var( --base-font-size );
letter-spacing: 0;
line-height: 14px;
}
.group {
  margin-top: 3%;
    display: grid;
    grid-template-columns: 270px 270px 270px 270px;
    margin-left: 3.9%;
}

.group1 {
  margin-left: 3.5%;
  display: grid;
  grid-template-columns: 270px 270px 270px 270px;  
}

.showGroup {
  position: fixed;
    color: red;
}

.creation-page{
  margin-top: -5%;
}
.creation-back{
  width: 100%;
  position: fixed;
  margin-bottom: 2%;
  margin-left: -4% !important;
  margin-top: 6.5%;
}
.pack-box{
  margin-top: 3%;
  height: 34px;
  width:  137px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
} 
.button-save-group {
  background-color: Transparent;
  cursor: pointer;
  box-sizing: border-box;
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
  margin-right: 2%;

}
.group-box {
  margin-top: 3%;
  box-sizing: border-box;
  height: 34px;
  width: 85%;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.button-continue-group {
  position: absolute;
  bottom: 10%;
  border: none;
  left:82%;
  height: 35px;
  width: 150px;
  border-radius: 19px;
  background-color: #f28f7b;
}

@media screen and (min-width: 1200px){
  .white-rectangle {
    /* top: 253px !important; */
    top: 210px !important;
    left: 115px !important;
    /* height: 330px !important; */
    height: 375px !important;
    width: 1130px !important;
  }
  .header-fix4 {
    margin-left: 8.45% !important;
    width: 83.1% !important;
   }
   .group-information-creation {
    margin-top: 3% !important;
    margin-left: 3.5%;
    font-size: 16px;
 }
  .group {
    margin-top: 3%;
      grid-template-columns: 270px 270px 270px 270px !important;
      margin-left: 3.9% !important;
  }
  
  .group1 {
    margin-left: 3.5% !important;
    grid-template-columns: 270px 270px 270px 270px !important;  
  }
  
  .creation-page{
    margin-top: -5%;
  }
  .creation-back{
    width: 100%;
    position: fixed;
    margin-bottom: 2%;
    margin-left: -4% !important;
    margin-top: 6.5%;
  }
 

  .group-box {
    margin-top: 3%;
    box-sizing: border-box;
    height: 34px;
    width: 85%;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
  }


}

 @media screen and (min-width: 992px) and (max-width: 1199px){
  .all-rights-reserved-group1{
    margin-left: 2%;
   }
   .all-rights-reserved-group{
    top: 78% !important;
    left: 64% !important;
  
  }
  .white-rectangle {
    position: absolute;
    /* top:21%; */
    top:17.2%;
    left: 1.5%;
    /* height: 56%; */
    height: 58%;
    width: 70%;

  }
  .group-information-creation {
    margin-top: 13% !important;
    margin-left: 3.5%;
    font-size: 16px;
 }
 .group {
  margin-top: 3%;
    grid-template-columns: 230px 230px 230px 230px;
    margin-left: 3.9%;
}
.header-fix4 {
  margin-left: 1.5% !important;
    /* margin-top: 163px !important; */
    margin-top: 132.4px !important;
    position: absolute;
    height: 102px;
    background: #ffffff;
    width: 70% !important;
    z-index: 1;
}
.group1 {
  margin-left: 3.5%;
  grid-template-columns: 230px 230px 230px 230px;  
}
.creation-button {
  grid-template-columns: 170px 170px;
  margin-left: 65%;
  margin-top: 5%;
}
  .oval-creation {
    width: 1.5%;
    top: 73%;
    left: 7%;
  }
  .line-creation{
    top: 77%;
    left: 8%;
    width: 83% !important;
  }
  .oval-creation1 {
    width: 1.5%;
    top: 73%;
    left: 20.83%;
  }
  .oval-creation2{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.5%;
    background-color: #EEF1F2;
    border-radius: 10px;
  }
  
  .oval-creation3 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 48.5%;
    right: 507px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
  }
  .oval-creation4 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 62.3%;
    right: 365px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
  }
  .oval-creation5 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
  }
  .oval-creation6 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.5%;
    border-radius: 10px;
    background-color: #EEF1F2;
  }
  .group-information2{
    position: absolute;
    top: 50%;
    bottom: 344px;
    left: 3%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .product-information-creation{
    position: absolute;
    top: 50%;
    left: 16%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .company-creation {
    position: absolute;
    top: 50%;
    left: 30%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .dimensions{
    position: absolute;
    top: 50% !important;
    bottom: 344px;
    left: 46% !important;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .item{
    position: absolute;
    top: 50% !important;
    bottom: 344px;
    left: 60% !important;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .additional-creation{
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .file{
    position: absolute;
    top: 50% !important;
    left: 88% !important;
    right: 42px;
    height: 14px;
    width: 1130px;
    color: #B7B7B7;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  
}


@media screen and (min-width: 768px) and (max-width: 991px){
  .all-rights-reserved-group1{
    width: 30%;
    margin-left: 4%;
   }
   .all-rights-reserved-group{
    left: 47%;
    top: 80% !important;
   }
  .white-rectangle {
    /* top:21%; */
    top:17.2%;
    left: 4%;
    /* height: 56%; */
    height: 58%;
    width: 50%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--main-bg-color);
  }
  .group-information-creation {
    margin-top: 7%;
    margin-left: 3.5%;
    font-size: 16px;
}
    .header-fix4 {
      margin-left: 40% !important;
      /* margin-top: 163px; */
      margin-top: 132.4px;
      position: absolute;
      height: 32% !important;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      width: 13% !important;
      z-index: 1;
      display: none;
    }
    .group-information2 {
      top: 5%;
      left: 30%;
    }
    .product-information-creation {
      top: 20%;
      left: 30%;
    }
    .company-creation {
      top: 35%;
      left: 30%;
    }
    .dimensions{
      position: absolute;
      top: 50% !important;
      left: 30% !important;
    }
    .item{
      top: 65% !important;
      left: 30% !important;
    }
    .additional-creation {
      top: 80% !important;
      left: 30% !important;
    }
    .file{
      top: 95% !important;
      left: 30% !important;
    }
    .oval-creation {
      top: 5%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .line-creation{
      top: 5%;
      left: 20%;
      right: 73px;
      height: 90%;
      width: 2%;
    }
    .oval-creation1 {
      top: 20%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .oval-creation2{
      top: 35%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .oval-creation3{
      top: 50%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .oval-creation4{
      top: 65%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .oval-creation5{
      top: 80%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
    .oval-creation6{
      top: 95%;
      left: 18%;
      height: 12px;
      width: 12px;
    }
  .group {
    margin-top: 4%;
      display: grid;
      grid-template-columns: 270px 270px;
      margin-left: 3.5%;
      grid-row-gap: 70px;
  }
  .group1 {
    margin-left: 3.5%;
    display: grid;
    grid-template-columns: 270px 270px;
    grid-row-gap: 50px;
    margin-top: -13%;
  }
  .creation-button {
    display: grid;
    grid-template-columns: 170px 170px;
    margin-left: 45%;
    margin-top: 8%;
  }
  .button-continue-group{
    left: 70%;
  }
} 

@media screen and (min-width: 601px) and (max-width: 767px){
  .all-rights-reserved-group1{
    margin-left: 4%;
    width: 30%;
   }
  .all-rights-reserved-group {
    left: 47%;
    top: 80% !important;
}
  .button-continue-group{
    left: 70%;
  }
  .white-rectangle {
    position: absolute;
    /* top:21%; */
    top: 17.2%;
    left: 4% !important;
    height: 56%;
    width: 50% !important;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--main-bg-color);
  }
  .group-information-creation {
    margin-top: 7%;
    margin-left: 3.5%;
    font-size: 16px;
}
  .group {
    margin-top: 4%;
      display: grid;
      grid-template-columns: 270px 270px;
      margin-left: 3.5%;
      grid-row-gap: 70px;
  }
  .group1 {
    margin-left: 3.5%;
    display: grid;
    grid-template-columns: 270px 270px;
    grid-row-gap: 50px;
    margin-top: -13%;
  }
  .creation-button {
    display: grid;
    grid-template-columns: 170px 170px;
    margin-left: 45%;
    margin-top: 8%;
  }
  .header-fix4 {
    margin-left: 40% !important;
    /* margin-top: 163px !important; */
    margin-top: 132.4px !important;
    position: absolute;
    height: 32% !important;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    width: 13% !important;
    z-index: 1;
    display: none;
  }
  .group-information2 {
    top: 5%;
    left: 30%;
  }
  .product-information-creation {
    position: absolute;
    top: 20%;
    left: 30%;
  }
  .company-creation {
    position: absolute;
    top: 35%;
    left: 30%;
  }
  .dimensions{
    position: absolute;
    top: 50% !important;
    left: 30% !important;
  }
  .item{
    top: 65% !important;
    left: 30% !important;
  }
  .additional-creation {
    top: 80% !important;
    left: 30% !important;
  }
  .file{
    top: 95% !important;
    left: 30% !important;
  }
  .oval-creation {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .line-creation{
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
  }
  .oval-creation1 {
    top: 20%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation2{
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation3{
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation4{
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation5{
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation6{
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
}

@media screen and (min-width: 400px) and (max-width: 600px){
  .white-rectangle {
    position: absolute;
    top: 18%;
    bottom: 89px;
    left: 0.5%;
    height: 60%;
    width: 43%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--main-bg-color);
  }
  .group {
    margin-top: 3%;
      display: grid;
      grid-template-columns: 270px;
      margin-left: 7%;
      grid-row-gap: 70px;
  }
  .group-information-creation{
    margin-top: 6%;
    margin-left: 7%;
    font-size: 15px;
  }
  .group1 {
    margin-left: 7%;
    display: grid;
    grid-template-columns: 270px;
    grid-row-gap: 47px;
    margin-top: -46%;
  }
  .group-box {
    margin-top: 3%;
    box-sizing: border-box;
    height: 34px;
    width: 115%;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}
  .creation-button {
    margin-top: -3%;
    margin-left: 55%;
    width: 100%;
    grid-template-columns: 100px 80px;
  }
   .header-fix4 {
    margin-left: 30% !important;
    /* margin-top: 163px !important; */
    margin-top: 132.4px !important;
    position: absolute;
    height: 32% !important;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    width: 13% !important;
    z-index: 1;
    display: none;
  }
  .group-information2 {
    top: 5%;
    left: 30%;
  }
  .product-information-creation {
    position: absolute;
    top: 20%;
    left: 30%;
  }
  .company-creation {
    position: absolute;
    top: 35%;
    left: 30%;
  }
  .dimensions{
    position: absolute;
    top: 50% !important;
    left: 30% !important;
  }
  .item{
    top: 65% !important;
    left: 30% !important;
  }
  .additional-creation {
    top: 80% !important;
    left: 30% !important;
  }
  .file{
    top: 95% !important;
    left: 30% !important;
  }
  .oval-creation {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .line-creation{
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
  }
  .oval-creation1 {
    top: 20%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation2{
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation3{
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation4{
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation5{
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation6{
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
} 

@media screen and (max-width: 399px){
  .white-rectangle {
    position: absolute;
    top: 16%;
    bottom: 89px;
    left: 0.5%;
    height: 62%;
    width: 26.5%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--main-bg-color);
  }
  .group {
    margin-top: 3%;
      display: grid;
      grid-template-columns: 270px;
      margin-left: 7%;
      grid-row-gap: 70px;
  }
  .group-information-creation{
    margin-top: 6%;
    margin-left: 7%;
    font-size: 15px;
  }
  .group1 {
    margin-left: 7%;
    display: grid;
    grid-template-columns: 270px;
    grid-row-gap: 47px;
    margin-top: -75%;
  }
  .group-box {
    margin-top: 3%;
    box-sizing: border-box;
    height: 34px;
    width: 115%;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}
  .creation-button {
    margin-top: 6%;
    margin-left: 35%;
    grid-template-columns: 115px 120px;
    width: 100%;
  }
  .header-fix4 {
    margin-left: 13.5% !important;
    margin-top: 130px !important;
    position: absolute;
    height: 32% !important;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    width: 13% !important;
    z-index: 1;
    display: none;
  }
  .group-information2 {
    top: 5%;
    left: 30%;
  }
  .product-information-creation {
    position: absolute;
    top: 20%;
    left: 30%;
  }
  .company-creation {
    position: absolute;
    top: 35%;
    left: 30%;
  }
  .dimensions{
    position: absolute;
    top: 50% !important;
    left: 30% !important;
  }
  .item{
    top: 65% !important;
    left: 30% !important;
  }
  .additional-creation {
    top: 80% !important;
    left: 30% !important;
  }
  .file{
    top: 95% !important;
    left: 30% !important;
  }
  .oval-creation {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .line-creation{
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
  }
  .oval-creation1 {
    top: 20%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation2{
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation3{
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation4{
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation5{
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-creation6{
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
} 
.Fmsg-creation{
  color:red;
  margin-top: 6%;
  margin-left: 28%;
}
@media screen and (min-width: 1367px){
.header-fix4 {
  margin-left: 10.5% !important;
  width: 83% !important;
 }
.white-rectangle {
    /* top: 253px !important; */
    top: 210px !important;
    left: 10.5% !important;
    height: 330px !important;
    width: 1130px !important;
  }
 .all-rights-reserved-group1{
    margin-left: 11%;
   }
 .all-rights-reserved-group{
    top: 78% !important;
    left: 86% !important;
  
  }
}