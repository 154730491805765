.in-the-box {
    width: 100% !important;
    margin-bottom: 5%;
}

.rectangle3 {
    box-sizing: border-box;
    margin-top: 3%;
    height: 35px;
    /* width: 217px; */
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid #d8d8d8;
    background-color: var(--main-bg-color);
}