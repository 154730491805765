   .user-icon {
    position: absolute;
    top: 5.08%;
    left: 64.71%;
    bottom: 92.58%;
    right: 33.97%;
    height: 2.34%;
    width: 1.32%;
  }
  .logout-icon {
    position: absolute;
    top: 5.34%;
    bottom: 92.32%;
    left: 86.54%;
    right: 12.13%;
    height: 2.34%;
    width: 1.32%;
  }
  .welcome-pacetel-syst {
    position: absolute;
    left: 66.69%;
    right: 17.72%;
    top: 5.6%;
    bottom: 92.45%;
    height: 1.95%;
    width: 15.29%;
    color: #FFFFFF;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .logout {
    position: absolute;
    left: 88.82%;
    right: 8.53%;
    top: 5.6%;
    bottom: 92.45%;
    height: 1.95%;
    width: 2.65%;
    color: #FFFFFF;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
  }
  .abc {
    color: #414141 !important;
    text-decoration: none;
  }
  .logoutHref {
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
  }
  @media screen and (min-width: 1367px) {
    .user-icon {
      left: 68% !important;
    }
    .logout-icon {
      left: 88% !important;
    }
    .welcome-pacetel-syst {
      left: 70% !important;
      width: 100% !important;
    }
    .logout {
      left: 90% !important;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .user-icon {
      left: 46% !important;
    }
    .logout-icon {
      left: 65% !important;
    }
    .welcome-pacetel-syst {
      left: 48% !important;
      width: 100% !important;
    }
    .logout {
      left: 67% !important;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .user-icon {
      left: 30% !important;
    }
    .logout-icon {
      left: 49% !important;
    }
    .welcome-pacetel-syst {
      left: 32% !important;
      width: 100% !important;
    }
    .logout {
      left: 51% !important;
    }
  }
  @media screen and (min-width: 601px) and (max-width: 767px) {
    .user-icon {
      left: 30% !important;
    }
    .logout-icon {
      left: 49% !important;
    }
    .welcome-pacetel-syst {
      left: 32% !important;
      width: 100% !important;
    }
    .logout {
      left: 51% !important;
    }
  }
  @media screen and (min-width: 400px) and (max-width: 600px) {
    .user-icon {
      left: 27%;
    }
    .logout-icon {
      left: 37%;
    }
    .welcome-pacetel-syst {
      left: 29%;
      width: 8% !important;
    }
    .logout {
      left: 39%;
    }
  }
  
  @media screen and (max-width: 399px) {
    .user-icon {
      top: 3.5% !important;
      left: 11% !important;
    }
    .logout-icon {
      top: 4% !important;
      left: 21% !important;
    }
  
    .welcome-pacetel-syst {
      top: 4% !important;
      left: 13% !important;
      width: 8% !important;
    }
    .logout {
      left: 22.5% !important;
      top: 4.3% !important;
    }
  }
  .button-Yes {
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    height: 34px;
    width: 100px;
    background-color: #f28f7b;
    border-radius: 18px;
    margin-left: 68%;
    margin-top: -10%;
  }
  .button-No {
    background-color: Transparent;
    cursor: pointer;
    margin-top: 10%;
    margin-left: 20%;
    box-sizing: border-box;
    height: 34px;
    width: 100px;
    border: 1px solid #49a7a2;
    border-radius: 18px;
  }
  .LogoutMessage {
    margin-top: 2%;
    margin-left: 18%;
    font-weight: bold;
    font-size: 20px;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
  }
  .logoutIcon {
    margin-top: 6%;
    margin-left: 42%;
  }
  .LogoutMessage1 {
    margin-top: 10%;
    margin-left: 5%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 20px;
    word-spacing: 1.5px;
  }
  .noBody {
    margin-top: 2%;
    margin-left: 13%;
    width: 40%;
    
  }
  .yesBody {
    margin-top: -7%;
    margin-left: 35%;
    width: 23%;
    }
    .Yes {
      text-decoration: none;
      cursor: pointer;
      color:white;
    }
    .No {
      text-decoration: none;
      cursor: pointer;    
    }
  
