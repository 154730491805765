.mainbox {
  position: absolute;
  top: 15%;
  left: 9%;
  height: 72%;
  width: 83%;
  background-color: var(--main-bg-color);
  }
.pagenotfound{
  margin-top: 3%;
  margin-left: 33%;
  height: 40%;
  width: 35%;
}
.msg {
  text-align: center;
  margin-top: 1%;
  margin-left: 14%;
  width: 75%;
  color: #49A7A2;
  font-family: var(--main-font-family);
  font-size: 31px;
  font-weight: bold;
}
.may-be-this-page-mov {
  margin-top: 1%;
  margin-left: 34%;
  width: 35%;
  color: #323232;
  font-family: var(--main-font-family);
  font-size: 14px;
  text-align: center;
}
.page-not-found {
  margin-top: 16%;
  margin-left: 34%;
  width: 35%;
  color: #323232;
  font-family: var(--main-font-family);
  font-size: 14px;
  text-align: center;
}
.letsgo {
  margin-top: 0.5%;
  margin-left: 50%;
  color: #000000;
  font-family: var(--main-font-family);
  font-size: 13px;
  font-weight: bold;
}
.button-page {
  border: none;
  margin-top: 0.5%;
  margin-left: 45%;
  height: 35px;
  width: 150px;
  border-radius: 19px;
  background-color: #f28f7b;
}
.home{
  color: white;
}
.try{
  margin-top: 0.5%;
  margin-left: 48%;
  color: #000000;
  font-family: var(--main-font-family);
  font-size: 13px;
  font-weight: bold;
}
.bimage{
 height: max-content;
 width: 100%;
}
.all-rights-reserved-page{
  margin-top: -9%;
  margin-left: 83%;
  z-index: 1;
  height: 14px;
  width: 100px;
  color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
  letter-spacing: 0;
  line-height: 14px;
} 
.all-rights-reserved-page1{
  position: absolute;
  margin-top: 0%;
  margin-left: 10%;
  color: #77fcfb;
  font-family: var(--main-font-family-regular);
  font-size: var(--disclaimer-font-size);
}
@media screen and (min-width: 601px) and (max-width: 991px){
  .letsgo {
    margin-top: 0.5%;
    margin-left: 48%;
    
  }
  .button-page {
    margin-top: 0.5%;
    margin-left: 40%;
    height: 35px;
    width: 150px;
  
  }
 .try{
    margin-top: 0.5%;
    margin-left: 45%;
  }
  .all-rights-reserved-page1{
    margin-top: -1%;
    width: 50%;
  }
  .all-rights-reserved-page{
    margin-top: -15%;
    margin-left: 79% !important;
  }

  .mainbox{
    height: 52%;
    top: 11%;
  }
}
