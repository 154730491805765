.white-rectangle1 {
  position: absolute;
  top: 163px;
  bottom: 89px;
  left: 115px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 350x;
  width: 1130px;
  background-color: var(--main-bg-color);
}
.digital1 {
  position: absolute;
  height: 14px;
  top: 17px;
  left: 871px;
  color: #424242;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.line-rectangle1 {
  position: absolute;
  top: 65%;
  bottom: 10%;
  left: 8%;
  right: 8%;
  height: 4%;
  width: 86%;
  background-color: #EEF1F2;
}
.errormsg-add {
  color: red;
  margin-top: 5%;
}
.confirmmsg-add{
  color:red;
  margin-top: -12%;
  margin-left: 28%;
}

@media screen and (max-width: 1199px) and (min-width: 992px)
{
.line-rectangle1 {
    top: 77%;
    left: 8%;
    width: 83% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.line-rectangle1 {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 90%;
    width: 2%;
}
}
.orange-line1 {
  position: absolute;
    top: 65%;
    bottom: 10%;
    left: 8%;
    right: 8%;
    height: 4%;
    width: 73%;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
 .orange-line1 {
    top: 77%;
    left: 8%;
    width: 69%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.orange-line1 {
    top: 5%;
    left: 20%;
    right: 73px;
    height: 15%;
    width: 2%;
}
}
.ovaladd {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 8%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
    background-color: #F9BC91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd {
  width: 1.3% !important;
  top: 73% !important;
  left: 7% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd {
    top: 5%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.ovaladd1 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 22.16%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd1 {
  width: 1.3%;
    top: 73%;
    left: 20.83%;
}
}
@media screen and (max-width: 991px) and (min-width: 601px){
.ovaladd1 {
    top: 20% !important;
    left: 18% !important;
    height: 12px !important;
    width: 12px !important;
}
}
.ovaladd2 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 39%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd2 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 34.6%;
    right: 675px;
    height: 14px;
    width: 1.3%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd2 {
    top: 35%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.ovaladd3 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 54%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd3 {
  position: absolute;
  top: 73%;
  bottom: 317px;
  left: 48.5%;
  right: 507px;
  height: 14px;
  width: 1.3%;
  border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd3 {
    top: 50%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.ovaladd4 {
  position: absolute;
  top: 61%;
  bottom: 10%;
  left: 67%;
  right: 20%;
  height: 12%;
  width: 1.2%;
  border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd4 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 62.3%;
    right: 365px;
    height: 14px;
    width: 1.3%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd4{
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.ovaladd5 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 80%;
    right: 20%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
  background-color: #f9bc91;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd5 {
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 76.15%;
    right: 215px;
    height: 14px;
    width: 1.3%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd5 {
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.ovaladd6 {
  position: absolute;
    top: 61%;
    bottom: 10%;
    left: 93%;
    right: 10%;
    height: 12%;
    width: 1.2%;
    border-radius: 10px;
  background-color: #eef1f2;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.ovaladd6{
    position: absolute;
    top: 73%;
    bottom: 317px;
    left: 90%;
    right: 64px;
    height: 14px;
    width: 1.3%;
    border-radius: 10px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.ovaladd6{
    top: 95%;
    left: 18%;
    height: 12px;
    width: 12px;
}
}
.group-information1 {
  position: absolute;
    top: 40%;
    bottom: 10%;
    left: 3.5%;
    right: 42px;
    height: 10%;
    width: 83%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.group-information1 {
    position: absolute;
    top: 50%;
    bottom: 344px;
    left: 3%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.group-information1 {
    top: 5% !important;
    left: 30% !important;
}
}
.product-information-additional {
  position: absolute;
    top: 40%;
    left: 18.5%;
    right: 10%;
    height: 10%;
    width: 80%;
    color:var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.product-information-additional {
    position: absolute;
    top: 50%;
    left: 16%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.product-information-additional {
  top: 19% !important;
  left: 30% !important;
}
}
.company-info-comp {
  position: absolute;
  top: 40%;
  left: 34.5%;
  right: 10%;
  height: 10%;
  width: 95%;
  color:var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.company-info-comp {
    position: absolute;
    top: 50%;
    left: 30%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.company-info-comp {
  top: 34% !important;
  left: 30% !important;
}
}
.dimensions1 {
  position: absolute;
    top: 40%;
    bottom: 30%;
    left: 52%;
    right: 10%;
    height: 10%;
    width: 95%;
    color:var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.dimensions1 {
  
    top: 50% !important;
    left: 46% !important;
    
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.dimensions1 {
  top: 49% !important;
  left: 30% !important;
}
}

.itemadditional {
  position: absolute;
    top: 40%;
    bottom: 30%;
    left: 64.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.itemadditional {
    top: 50% !important;
    left: 60% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.itemadditional {
  top: 64% !important;
  width: 60% !important;
  left: 30% !important;
}
}
.additional {
  position: absolute;
    top: 40%;
    left: 76.5%;
    right: 10%;
    height: 10%;
    width: 95%;
    color:var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.additional{
    position: absolute;
    top: 50%;
    left: 72.5%;
    right: 42px;
    height: 14px;
    width: 20%;
    font-family: var(--main-font-family);
    font-size: var( --base-font-size );
    letter-spacing: 0;
    line-height: 14px;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.additional {
  top: 79% !important;
  left: 30% !important;
}
}
.file1 {
  position: absolute;
  top: 48px;
  left: 1019px;
  height: 14px;
  color: var(--main-txt-color);
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}


.button-save-ad{
  position: absolute;
  background-color: Transparent;
  cursor: pointer;
  overflow: hidden;
  left: 771px;
  margin-bottom: 4%;
  box-sizing: border-box;
  height: 34px;
  width: 151px;
  border: 1px solid #49a7a2;
  border-radius: 18px;
}
.save-item {
  position: absolute;
  top: 10px;
  left: 61px;
  height: 14px;
  width: 28px;
  color: #3b3535;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.button-continue-ad {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  left: 941px;
  margin-bottom: 4%;
  height: 34px;
  width: 151px;
  border-radius: 19px;
  background-color: #f28f7b;
}
.continue{
  position: absolute;
  top: 10px;
  left: 51px;
  height: 14px;
  width: 49px;
  color: #ffffff;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
  letter-spacing: 0;
  line-height: 14px;
}
.all-rights-reserved-ad1{
  z-index: 1;
  margin-top: 44%;
  margin-left: 8.5%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size);
 }
.all-rights-reserved-ad {
  z-index: 1;
  /* margin-left: 24%; */
  margin-left: 13.1%;
  margin-top: 44%;
   color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
}

.camera-back-ad {
  position: relative;
  margin-top:  100%;
  margin-bottom: 4%;
  right:40vw;
  height: 40px;
  width: 44px;
}
.header-fix4 {
  margin-left: 8.46%;
    /* margin-top: 12%; */
    margin-top: 9.72%;
    position: absolute;
    /* height: 16%; */
    height: 13.5%;
    background: #ffffff;
    width: 83.09%;
    z-index: 1;
}
@media screen and (max-width: 1199px) and (min-width: 992px)
{
.header-fix4 {
    margin-left: 1.5% !important;
    /* margin-top: 163px !important; */
    margin-top: 132.4px !important;
    position: absolute;
    height: 102px;
    background: #ffffff;
    width: 70% !important;
    z-index: 1;
}
.delete-additional {
  margin-top: -10%;
  margin-left: 88% !important;
}
}
@media screen and (max-width: 991px) and (min-width: 601px)
{
.header-fix4 {
  margin-left: 40% !important;
  /* margin-top: 163px !important; */
  margin-top: 132.4px !important;
  position: absolute;
  height: 32% !important;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  width: 13% !important;
  z-index: 1;
  /* display: none; */
}
}
.additional-button{
  width: 32%;
  margin-left: 60%;
}
.additional-back{
  margin-bottom: 2%;
  margin-left: 3% !important;
  margin-top: 9.5%;
}
@media screen and (max-width: 991px) and (min-width: 601px){
.additional-back {
    margin-top: 32%;
}
}
.additionallabel3{
  margin-top: 5%;
}
.additionallabel2{
  margin-top: 15%;
}
.radiolabel{
  margin-left: 3%;
}

.item-box {
  height: auto;
  margin-left: 4%;
}
.st-line-additional {
  margin-top: 5% !important;
  margin-left: 3.3%;
  height: 1px;
  width: 1048px;
  border-bottom: 1px solid #d8d8d8;
}
.product-box-product2 {
  margin-top: 11%;
  box-sizing: border-box;
  height: 34px;
  width: 220px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}
.add-rectangle {
  box-sizing: border-box;
  margin-top: 11%;
  height: 34px;
  width: 217px;
  border: 1px solid #d8d8d8;
  background-color: var(--main-bg-color);
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .oval-item4{
    top: 65%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .oval-additional5{
    top: 80%;
    left: 18%;
    height: 12px;
    width: 12px;
  }
  .item-details{
    top: 65% !important;
    left: 30% !important;
  }
  .additional{
    top: 80% !important;
    left: 30% !important;
  }
  .orange-line1{
    top: 5%;
    left: 20%;
    height: 80%;
    width: 4px;
  }
  .dimensions-dm{
    position: absolute;
    top: 50%!important;
    left: 30%!important;
  }
  .oval-dimension3{
    top: 50% !important;
    left: 18% !important;
    height: 12px !important;
    width: 12px !important;
  }
  .delete-additional {
    margin-left: 80% !important;
}
}

.delete-additional {
  margin-top: -10%;
  margin-left: 93%;
}
.addData-additional {
  position: relative;
    margin-top: -12%;
    margin-left: 94%;
    z-index: 1; 
}
.deleteData-additional{
  position: relative;
    margin-top: -12%;
    margin-left: 94%;
    z-index: 1;
}
.select-box {
  margin-top: 3%;
  box-sizing: border-box;
  width: 220px !important;
  border: 0px solid #d8d8d8;
  background-color: var(--main-bg-color);
}

@media screen and (max-width: 1200px) and (min-width: 1000px){
  .all-rights-reserved-ad {
    margin-left: 6%;
  }
 }
@media screen and (max-width: 992px) and (min-width: 601px)
{
.all-rights-reserved-ad1{
  z-index: 1;
  width: 30%;
  margin-top: 46%;
  margin-left: 4%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var( --disclaimer-font-size );
 }
.all-rights-reserved-ad {
  z-index: 1;
  margin-left: 13%;
  margin-top: 47%;
   color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
}
.addData-additional{
  margin-left: 80%;
  margin-top: -9%;
}
.deleteData-additional{
  margin-left: 80%;
  margin-top: -9%;
}
}
@media screen and (min-width: 1367px){
  .all-rights-reserved-ad1{
      margin-left: 11%;
    }
  .all-rights-reserved-ad{
    margin-left: 24% !important
    }
 }