.ot4{
  color: red;
  margin-top: 36%;
  margin-left: 3%;
  width: 73%;
  text-align: center;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: -3%;
  font-weight: bold;
}
.ot-err{
  color: red;
  margin-left: 75px;
  margin-top: 130px;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
  font-weight: bold;
}
.top{
  
    font-size: 16px !important;
    font-family: var(--main-font-family-regular);
    margin-left: 45px !important;
    margin-bottom: 30px !important;
}
.top2{
  
  font-size: 15px !important;
  font-family: var(--main-font-family-regular);
  margin-left: 15px !important;
  margin-bottom: 30px !important;
}
.top1{
  
  font-size: 20px !important;
  font-family: var(--main-font-family-bold);
  margin-left: 45px !important;
  margin-bottom: 20px !important;
  margin-top: 30px !important;
}
.imageWelcome{
  margin-left: 60px !important;
 margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.buttondiv-No{
  position: relative !important;
  border-width: 1px !important;
  left: 13% !important;
  height: 34px !important;
  width: 100px !important;
  border-radius: 19px !important;
  background-color:white !important;
  border-color: #49A7A2 !important;
  color: black;
  font-size: 13px !important;
}
.buttondiv-No1{
  position: relative !important;
  border-width: 1px !important;
  left: 22% !important;
  height: 34px !important;
  width: 130px !important;
  border-radius: 19px !important;
  background-color:white !important;
  border-color: #49A7A2 !important;
  color: black;
  font-size: 13px !important;
}
.buttondiv-Yes{
  position: relative !important;
  border: none !important;

  left: 20%;
  height: 34px !important;
  width: 100px !important;
  border-radius: 19px !important;
  background-color:#F28F7B !important;
  color: white;
}
.buttondiv-Yes1{
  position: relative !important;
  border: none !important;
  left: 24%;
  height: 34px !important;
  width: 130px !important;
  border-radius: 19px !important;
  background-color:#F28F7B !important;
  color: white;
}
.ot5{
  color:black;
  margin-left: 46%;
  margin-top: -5.2%;
  font-family: var(--main-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: -2%;
  font-weight: bold;
}
.ot6{
  color: #525252;
  margin-left: -10%;
  margin-top: 39%;
  font-family: var(--main-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: -2%;
}
.ot7{
  color: #525252;
  margin-left: 2%;
  margin-top: 0%;
  font-family: var(--main-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}
.ot8{
  color:black;
  margin-left: 46%;
  margin-top: -4%;
  font-family: var(--main-font-family);
  font-size: 12px;
  letter-spacing: 0;
  line-height: -0.5%;
  font-weight: bold;
}
.ot{
  margin-left: -10px;
  color: #676767;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
}
.ot-1{
  margin-left: -12px;
  color: #676767;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
}
.ot1{
  color: #676767;
  position: absolute;
  left: 143px;
  top: 130px;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
  font-weight: bold;
}
.ot2{
  position: absolute;
  margin-left: 100px;
  margin-top: -2px;
  align-content: center;
  color: #676767;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;

}
.ot3{
  color: var(--main-txt-color);
  position: absolute;
  left: 100px;
  top: 145px;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
  font-weight: bold;
}
.sent{
  margin-top: 117px;
  font-size:small;
  margin-left: -50px;
  font-family: var(--main-font-family-regular) ;
  margin-right: -103px;
  color: #525252;
}
.title{
  position: absolute !important;
  top: 320px;
  left: 8.46%;
  right: 52.13%;
  height: 5.21%;
  width: 39.41%;
  color: #FFFFFF;
  font-family: var(--main-font-family) Bold ;
  font-size: 40px;
  line-height: 34px;
}
@media screen and (max-width: 1325px){
  .title{
   font-size: 25.7px;
  }
  
}
@media screen and (max-width: 1180px){
  .title{
    left: 50px;
    font-size: 20px;
  }
  
}
@media screen and (max-width: 980px){
  .title{
    left: 23%;
    font-size: 40px;
    top: 150px;
    width:70%;
  }
  
}
@media screen and (max-width: 800px){
  .title{
    left: 17%;   
  }
  
}
@media screen and (max-width: 712px){
  .title{
    font-size: 36px;
  }
  
}
@media screen and (max-width: 600px){
  .title{
    left: 15%;
  }
  
}
@media screen and (max-width: 586px){
  .title{
    font-size: 32px;
  }
  
}
@media screen and (max-width: 558px){
  .title{
    left: 14%;
  }
  
}
@media screen and (max-width: 529px){
  .title{
    left: 12%;
    font-size: 30px;
  }
  
}
@media screen and (max-width: 501px){
  .title{
    left: 24%;
    top: 90px;
    font-size:18px ;
  }
  
}
@media screen and (max-width: 470px){
  .title{
    left: 22%;
    top: 90px;
    font-size:18px ;
  }
  
}
@media screen and (max-width: 435px){
  .title{
    left: 21%;
    top: 60px;
    font-size:17px ;
    width: 65%;
  }
  
}
@media screen and (max-width: 378px){
  .title{
    left: 18%;
    font-size:15px ;
  }
  
}
@media screen and (max-width: 328px){
  .title{
    left: 19%;
    font-size: 14px;
  }
  
}
.your-product{
  top: 303px;
  left: 30%;
  color: #FFFFFF;
  font-family: var(--main-font-family)   ;
  font-size: 28px;
  line-height: 34px;
  width: 40%;
}
@media screen and (max-width: 1325px){
  .your-product{
   font-size: 18px;
  }
  
}
@media screen and (max-width: 1180px){
  .your-product{
    left: 20px;
    font-size: 10px;
    width: 300px;
  }
  
}
@media screen and (max-width: 980px){
  .your-product{
    font-size: 26px;
  }
  
}
@media screen and (max-width: 712px){
  .your-product{
    font-size: 23px;
  }
  
}
@media screen and (max-width: 586px){
  .your-product{
    font-size: 21px;
  }
  
}
@media screen and (max-width: 529px){
  .your-product{
    font-size: 19px;
  }
  
}
@media screen and (max-width: 501px){
  .your-product{
    font-size: 15px;
    width:150px;
  }
  
}
@media screen and (max-width: 435px){
  .your-product{
    font-size: 14px;
  }
  
}
@media screen and (max-width: 328px){
  .your-product{
    font-size: 12px;
  }
  
}
.pre-registered{
  position: absolute;
  left: 8.46%;
  top: 388px;
  bottom: 348px;
  right: 52.72%;
  height: 4.17%;
  width: 38.82%;
  color: #afeeee;
  font-family: var(--main-font-family) ;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}
@media screen and (max-width: 1325px){
  .pre-registered{
    font-size: 12px;
  }
  
}
@media screen and (max-width: 1180px){
  .pre-registered{
    left: 5%;
    width: 45%;
    font-size: 10px;
  }
  
}
@media screen and (max-width: 980px){
  .pre-registered{
    width: 65%;
    top: 200px;
    left: 22%;
    font-size: 14px;
  }
  
}
@media screen and (max-width: 800px){
  .pre-registered{
    width:68.75%;
    left: 15.63%;
    font-size: 14px;
  }
  
}
@media screen and (max-width: 733px){
  .pre-registered{
    width: 75%;
    font-size: 12px;
  }
  
}
@media screen and (max-width: 630px){
  .pre-registered{
    width: 450px;
    font-size: 11px;
  }
  
}
@media screen and (max-width: 600px){
  .pre-registered{
    left: 14%;
  }
  
}
@media screen and (max-width: 558px){
  .pre-registered{
    left: 12%;
  }
  
}
@media screen and (max-width: 529px){
  .pre-registered{
    left: 10%;
  }
  
}
@media screen and (max-width: 501px){
  .pre-registered{
    top:132px;
    width: 400px;
    text-align: center;
  }
  
}
@media screen and (max-width: 470px){
  .pre-registered{
    left:6%;
  }
  
}
@media screen and (max-width: 435px){
  .pre-registered{
    left:7%;
    top:95px;
    font-size: 9px;
    width: 350px;
    line-height: 0.9;
  }
  
}
@media screen and (max-width: 378px){
  .pre-registered{
    width: 280px;
    left:10%;
  }
  
}
@media screen and (max-width: 328px){
  .pre-registered{
    left:8%;
  }
  
}
.mail{
  font-family: var(--main-font-family) Bold ;
  color:white;
  /* font-weight:bold; */
}
@media screen and (max-width: 435px){
  .mail{
    font-size:12px;
  }
  
}
.square{
  position: absolute;
  left: 51.25%;
  right: 2.28%;
  top: 0px;
  bottom:30%;
    height: 700px;
    width: 46.47%;
    background-color: rgba(34,88,109,0.56);
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 1180px){
    .square{
      left: 50%;
      width: 45%;
    }
  }
  @media screen and (max-width: 980px){
    .square{
       top: 280px;
       left: 22%;
       width: 56%;
      height: 600px;
    }
    
  }
  @media screen and (max-width: 800px){
    .square{
       left: 16%;
       width: 68%;
    }
    
  }
  @media screen and (max-width: 660px){
    .square{
       left: 12%;
       width: 76%;
    }
    
  }
  @media screen and (max-width: 558px){
    .square{
       left: 10%;
       width: 80%;
    }
    
  }
  @media screen and (max-width: 529px){
    .square{
       left: 7%;
       width: 86%;
    }
    
  }
  @media screen and (max-width: 501px){
    .square{
      top:215px;
      height: 520px;
    }  
    
  }
  @media screen and (max-width: 435px){
    .square{
      left:3%;
      width:94%;
      top:150px;
      height: 465px;
    }  
    
  }
  @media screen and (max-width: 368px){
    .square{
      left:1.5%;
      width:97%;
      
    }  
    
  }
  /*@media screen and (min-height: 600px){
    .square{
    height: 500px;
    }
    
  }*/
.rectanglelogin1{
  position: absolute;
  left: 59.26%;
  top: 97px;
  bottom: 166px;
  right: 140px;
  height: 505px;
  width:30.44%;
  background-color: var(--main-bg-color);
  
 }
 
@media screen and (max-width: 1180px){
  .rectanglelogin1{
    left: 56%;
    width: 34%;
    /*right: 110px;*/
  }
  
}
@media screen and (max-width: 980px){
  .rectanglelogin1{
    top: 340px;
    left: 30%;
    width:40%;
    height:480px;
  }
  
}
@media screen and (max-width: 800px){
  .rectanglelogin1{
    left: 25%;
    width:50%;
  }  
}
@media screen and (max-width: 660px){
  .rectanglelogin1{
    left: 21%;
    width:58%;
  }  
}
@media screen and (max-width: 558px){
  .rectanglelogin1{
    left: 18%;
    width: 64%;
  }  
}
@media screen and (max-width: 529px){
  .rectanglelogin1{
    left: 15%;
    width: 70%;
  }  
}
@media screen and (max-width: 501px){
  .rectanglelogin1{
    top: 235px;
    width: 80%;
    left:10%;
  }  
}
@media screen and (max-width: 435px){
  .rectanglelogin1{
    top: 168px;
    width: 86%;
    left:7%;
    height:430px;
  }  
}
@media screen and (max-width: 368px){
  .rectanglelogin1{
       width: 92%;
    left:4%;
  }  
}
.login{
  position: absolute;
  top: 153px;
  /* left: 66.25%; */
  left: 72%;
    bottom: 593px;
  right: 17.21%;
  height: 8%;
  font-weight: 600;
  width: 30%;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: 1.3vw;
  line-height: 50%;
}
@media screen and (max-width: 1180px){
  .login{
    /* left: 63%; */
    left: 59.6%;
    font-size: 1.5vw;
  }
  
}
@media screen and (max-width: 980px){
  .login{
    /* left: 38%; */
    left: 34.5%;
    top:400px;
    font-size: 1.8vw;
  }
  
}
@media screen and (max-width: 800px){
  .login{
    /* left: 36%; */
    left: 30.6%;
    top:400px;
    font-size: 2.2vw;
  }
  
}
@media screen and (max-width: 670px){
  .login{
    /* left: 36%; */
    left: 27%;
    top:400px;
    font-size: 2.2vw;
  }
  
}
@media screen and (max-width: 560px){
  .login{
    left: 31.5%;
        font-size: 2.8vw;
        width: 50%;
  }
  
}
@media screen and (max-width: 529px){
  .login{
    /* left: 29.5%; */
    left: 23%;
        font-size: 3.2vw;
        /*width: 50%;*/
  }
  
}
@media screen and (max-width: 510px){
  .login{
    /* left: 29.5%; */
    left: 19%;
        font-size: 3.2vw;
        /*width: 50%;*/
  }
  
}
@media screen and (max-width: 501px){
  .login{
    top: 290px;
    width: 45%;
    height: 10%;
  }
  
}
@media screen and (max-width: 435px){
  .login{
    top: 210px;
    width: 60%;
    height: 10%;
    font-size:3.8vw;
    left:25.5%;
  }
  
}
@media screen and (max-width: 368px){
  .login{
    font-size:4.2vw;
    left:23%;
  }
  
}
/*@media screen and (max-width: 1000px){
  .rectanglelogin1{
    display: block;
  }
  
}*/
.user{
  color: #525252;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
  left: 10.88%;
  top:126px;
  position: absolute;
}
@media screen and (max-width: 435px){
  .user{
    left: 33px;
    top: 100px;
  }
  
}
@media screen and (max-width: 368px){
  .user{
    left: 22px;
  }
  
}
.rectanglelogin2{
  position: absolute;
  left: 10.88%;
  right: 18.24%;
  top: 153px;
  bottom: 318px;
  box-sizing: border-box;
  height: 34px;
  width: 57%;
  border: 1px solid rgb(216, 216, 216);
  background-color: inherit !important;
}
@media screen and (max-width: 435px){
  .rectanglelogin2{
    top: 125px;
    left:33px;
  }
  
}
@media screen and (max-width: 368px){
  .rectanglelogin2{
    left:22px;
  }
  
}
.rectanglelogin3{
  position: absolute;
  left: 70%;
  right: 20%;
  top: 153px;
  bottom: 318px;
  box-sizing: border-box;
  height: 7%;
  width: 25%;
  color: var(--main-txt-color);
  border: 1px solid #51A7A4;
  border-radius: 19px;
  background-color: inherit !important;
  font-size: 13px;
}
@media screen and (max-width: 435px){
  .rectanglelogin3{
    top: 125px;
  }
  
}
.disablelogin{
  position: absolute;
  left: 70%;
  right: 28px;
  top: 153px;
  bottom: 318px;
  box-sizing: border-box;
  height: 34px;
  width: 100px;
  color: grey;
  border: 1px solid grey;
  border-radius: 19px;
  background-color: inherit !important; 
  cursor: not-allowed !important;
}

button:focus {
  outline: inherit !important;
}

.enter-otp{
  position: absolute;
  left: 10.81%;
  right: 31.69%;
  top: 246px;
  bottom: 245px;
  height: 14px;
  width: 20%;
  color: #525252;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 435px){
  .enter-otp{
    top: 185px;
    left: 33px;
  }
  
}
@media screen and (max-width: 368px){
  .enter-otp{
    left: 22px;
  }  
}
.you-will-get-otp{
  position: absolute;
  left: 28%;
  top: 48%;
  height: 3%;
  color: #919191;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: -0.1%;
}
.rectanglelogin4{
  position: absolute;
  display: flex;
  flex-direction: row;
  margin: 2px;
  left:10.88%;
  top: 54%;
}
.rectanglelogin4>div>div{
  width:4vw;
  height:20px;
  border: '1px solid #D8D8D8';
  
} 
@media screen and (max-width: 1180px){

  .rectanglelogin4>div>div{
    width: 4.8vw;
  }  
  .in{
    left: 25% !important;
  }
  .counter{
    left: 45% !important;
  } 
}
@media screen and (max-width: 980px){
  .rectanglelogin4>div>div{
    width: 5.6vw;
    height: 50px;
  } 
  .you-will-get-otp{
    top: 50%;
  } 
  .enter-otp{
    top: 50.5%;
  }
}
@media screen and (max-width: 800px){
  .rectanglelogin4>div>div{
    width: 7vw;
  }  
  .didnt{
    left: 11% !important;
    top: 89.8% !important;
  } 
 
}
@media screen and (max-width: 660px){
  .rectanglelogin4>div>div{
    width: 8.2vw;
  }  
}
/* @media screen and (min-width: 600px)  and (max-width: 992px){
  .ot4{
    margin-left: -5%;
  }
} */
@media screen and (min-width: 769px)  and (max-width: 992px){
  .in{
    left: 35% !important;
    top: 1% !important;
  }
  .counter{
    left: 60% !important;
    top: 1% !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 768px){
.in{
  margin-left: 45% !important;
  top: 1% !important;
}
.counter{
  left: 45% !important;
  top: 1% !important;
} 
}
@media screen and (max-width: 558px){
  .rectanglelogin4>div>div{
    width: 9vw;
  }  
}
@media screen and (max-width: 529px){
  .rectanglelogin4>div>div{
    width: 10vw;
  }  
}
@media screen and (max-width: 501px){
  .rectanglelogin4>div>div{
    width: 11.3vw;
  }  
}
@media screen and (max-width: 435px){
  .rectanglelogin4>div>div{
    width: 12vw;
  }  
}
@media screen and (max-width: 435px){
  .rectanglelogin4{
    top: 200px;
  }
  
}
@media screen and (max-width: 368px){
  .rectanglelogin4{
    left: 5%;
  }
  .rectanglelogin4>div>div{
    width: 14vw;
  }
  
}
.space{
  left:60px
}
.file-preview{
  color: #FFFFFF !important;
  text-decoration: none;
}
.rectanglelogin11{
  position: absolute;
  left: 25%;
  right: 25%;
  top: 335px;
  bottom: 131px;
  height: 7%;
  width: 50%;
  border-radius: 19px;
  color:#FFFFFF;
  background-color: grey;
  border-width: inherit !important;
}
@media screen and (max-width: 435px){
  .rectanglelogin11{
    top: 270px;
  }
  
}
.rectanglelogin10{
  position: absolute;
  left: 25%;
  right: 25%;
  top: 345px;
  bottom: 131px;
  height: 7%;
  width: 50%;
  margin: 7px 0 0 0;
  border-radius: 19px;
  color:#FFFFFF;
  background-color: #F28F7B;
  border-width: inherit !important;
}
@media screen and (max-width: 435px){
  .rectanglelogin10{
    top: 270px;
  }
  
}

.abc {
  color: #000000 !important;
  text-decoration: none;
}
.didnt{
  position: absolute;
  left: 15%;
  top: 88%;
  height: 14px;
  width: 40%;
  text-decoration: none;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  line-height: 14px;
}
@media screen and (max-width: 435px){
  .didnt{
    top: 340px;
  }
  
}
@media screen and (max-width: 368px){
  .didnt{
    left: 12%;
  }
  
}
.in{
  margin-left:67%;
  color: var(--main-txt-color);
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  font-weight: bold;
  line-height: 14px;
}
 .timer{
  position: absolute;
  left: 20%;
  top: 1px;
  height: 15px;
  width: 10vw;
  color: lightseagreen;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  font-weight: bold;
  line-height: 14px;

} 
.counter{
  /* position: absolute;
  left:54%;
  bottom:11%;
  width:80%;
  top:5%; */
  margin-left: 3%;
  color: lightseagreen;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  font-weight: bold;
  line-height: 14px;

}
.top2Session{
  
  font-size: 15px !important;
  font-family: var(--main-font-family-regular);
  margin-left: 55px !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 1205px){
  .timer{
    width: 9vw;
  }
}
@media screen and (max-width: 1096px){
  .timer{
    width: 11vw;
  }
}
@media screen and (min-width: 1349px){
  .timer{
    width: 8vw;
  }
}
@media screen and (max-width: 980px){
  .timer{
    width: 13vw;
    left: 25%;
  }
}
@media screen and (max-width: 768px){
  .timer{
    width: 18vw;
    left: 20%;
  }
}
.resend{
  position: absolute;
  left: 218px;
  top: 432px;
  height: 15px;
  width: 89px;
  color: var(--main-txt-color);
  background-color: var(--main-bg-color);
  font-family: var(--main-font-family-regular);
  border: 1px solid white;
  font-size: var( --base-font-size );
  line-height: 14px;
}
.resendenable{
  position: absolute;
  left: 50%;
  top: 440px;
  height: 15px;
  width: 30%;
  color: black;
  background-color: var(--main-bg-color);
  font-family: var(--main-font-family-regular);
  border: 1px solid white;
  font-size: var( --base-font-size );
  line-height: 14px;
  font-weight: bold;
}
@media screen and (max-width: 435px){
  .resendenable{
    top: 337px;
  }
  
}
.resenddisable{
  position: absolute;
  left: 50%;
  top: 441px;
  height: 15px;
  width: 30%;
  color: gray;
  background-color: var(--main-bg-color);
  font-family: var(--main-font-family-regular);
  border: 1px solid white;
  font-size: var( --base-font-size );
  line-height: 14px;
  font-weight: bold;
}
@media screen and (max-width: 435px){
  .resenddisable{
    top: 337px;
  }
  
}

.userid{
  color: red;
  font-weight: bold;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 5% ;
  left: 30%;
  top:200px;
  position: absolute;
}
@media screen and (min-width:600px) and (max-width: 617px){

  .footer{
    padding-top: 150% !important;
   }
}
@media screen and (min-width:617px) and (max-width: 660px){

  .footer{
    padding-top: 140% !important;
   }
}
@media screen and (min-width:660px) and (max-width: 710px){

  .footer{
    padding-top: 130% !important;
   }
}
@media screen and (min-width:710px) and (max-width: 754px){

  .footer{
    padding-top: 125% !important;
   }
}
@media screen and (min-width:754px) and (max-width: 810px){

  .footer{
    padding-top: 120% !important;
   }
}
@media screen and (min-width:810px) and (max-width: 900px){

  .footer{
    padding-top: 110% !important;
   }
}
@media screen and (min-width:900px) and (max-width: 980px){

  .footer{
    padding-top: 100% !important;
   }
}
@media screen and (min-width:980px) and (max-width: 1025px){

  .footer{
    padding-top: 72% !important;
   }
}
@media screen and (min-width:1090px) and (max-width: 1135px){

  .footer{
    padding-top: 65% !important;
   }
}
@media screen and (min-width:1135px) and (max-width: 1195px){

  .footer{
    padding-top: 62% !important;
   }
}
@media screen and (min-width:1195px) and (max-width: 1280px){

  .footer{
    padding-top: 58% !important;
   }
}
@media screen and (min-width:1280px) and (max-width: 1400px){

  .footer{
    padding-top: 53% !important;
   }
}
.footer{
 padding-top: 68%;

}
.rightReserved{
  float: right;
  padding-right: 7% !important;
  color: #77FCFB !important;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
  text-align: right;
}
.disclaimer{
  text-align: left;
  padding-right: 8% !important;
  padding-left: 7% !important;
  color: #77FCFB !important;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );
}
.all1{
  margin-top: 54%;
  margin-left: 6%;
  position: absolute;
  color: #77FCFB;
  font-family: var(--main-font-family-regular);
  font-size: 12px;
 
}

.all{
  position: absolute;
  left: 90% !important;
  right:20%;
  top: 731px;
  bottom: 23px;
  height: 14px;
  width: 10%;
  color: #77FCFB;
  font-family: var(--main-font-family-regular);
  font-size: var( --base-font-size );

}
@media screen and (max-width: 980px){
  .all{
    top: 910px;
     width: 20%;
  }
  
}
@media screen and (max-width: 501px){
  .all{
     top: 760px;
     left: 73%;
  }
  
}
@media screen and (max-width: 435px){
  .all{
     top: 630px;
     left: 73%;
     font-size:12px;
  }
  
}
@media screen and (max-width: 368px){
  .all{
     left: 65%;
     width:95px;
  }
  
}
.logo{
  position: absolute;
  left: 3.31%;
  right: 86.69%;
  /* top: 5.21%; */
  top:1%;
  bottom: 683px;
  height: 45px;
  width: 10%;
}
@media screen and (max-width: 630px){
  .logo img{
    width:110px;
    height: 38px;
    }
  
}
@media screen and (max-width: 501px){
  .logo{
    top: 25px;
    left: 10px;
    }
  
}
@media screen and (max-width: 435px){
  .logo{
    top: 20px;
    left: 8px;
    }
  
}
@media screen and (max-width: 435px){
  .logo img{
    width:85px;
    height: 25px;
    }
  
}
.err-msg{
  position: absolute;
  color: red;
  top: 61%;
  left: 12%;
  width: 73%;
  text-align: center;
  /* word-break: break-all; */
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 15px;
  font-weight: bold;
}
@media screen and (max-width: 980px){
  .err-msg{
    top: 65%;
  }
  
}
@media screen and (max-width: 1180px){
  .title{
    left: 50px;
    font-size: 20px;
  }
  .ot5{
    margin-left: 48.5%;
    margin-top: -5.5%;
  }
  .ot8{
    margin-left: 48.5%;
    margin-top: -5%;
  }
  
}
@media screen and (max-width: 980px){
  .title{
    left: 23%;
    font-size: 40px;
    top: 150px;
    width:70%;
  }
  
}
@media screen and (max-width: 800px){
  .title{
    left: 17%;   
  }
  .ot5{
    margin-left: 45.5%;
    margin-top: -5.5%;
  }
  .ot8{
    margin-left: 45.5%;
    margin-top: -4%;
  }
  
}
.rmv-page-scroll{
  overflow-x: hidden;
  background:black;
}
@media screen and (min-width: 600px) and (max-width: 991px){
  .ot6{
    margin-left: -14%;
  }
}