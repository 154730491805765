.rectangle{
  position: absolute;
  height: 768px;
width: 1360px;
}
.white-rectangle-ty{
  position: absolute;
    top: 21%;
    left: 9%;
    height: 55%;
    width: 83%;
    background-color: var(--main-bg-color);
}
@media screen and (max-width: 1200px){
  .white-rectangle-ty{
    left: 3%;
    width: 70%;
  }
  
}
@media screen and (max-width: 992px){
  .white-rectangle-ty{
    left: 4%;
    width: 48%;
    height: 80%;
  }
  
}
.logo-copy{
  position: absolute;
  height: 45px;
width: 136px;
top: 36px;
left: 117px;
}
.user-icon{
  position: absolute;
  top: 5.08%;
  left: 64.71%;
  bottom: 92.58%;
  right: 33.97%;
  height: 2.34%;
  width: 1.32%;
}
.logout-icon{
  position: absolute;
  top: 5.34%;
  bottom: 92.32%;
  left: 86.54%;
  right: 12.13%;
  height: 2.34%;
  width: 1.32%;
}
.welcome-pacetel-syst{
  position: absolute;
  left: 66.69%;
  right: 17.72%;
  top: 5.6%;
  bottom: 92.45%;
  height: 1.95%;
  width: 15.29%;
  color: #FFFFFF;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.logout{
  position: absolute;
  left: 88.82%;
  right: 8.53%;
  top: 5.6%;
  bottom: 92.45%;
  height: 1.95%;
  width: 2.65%;
  color: #FFFFFF;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}

.thank-you {
  position: absolute;
  top: 15%;
  right: 30%;
  left: 34%;
  bottom: 20%;
  height: 20%;
  width: 30%;
  color: #F28F7B;
  font-size: 64px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 103px;
}
@media screen and (max-width: 1200px){
  .thank-you{
   left:31%;
  }
  
}
@media screen and (max-width: 992px){
  .thank-you{
    left: 22%;
  }
  
}

.all-rights-reserved-thanku{
  z-index: 1;
  margin-top: 45.1%;
  margin-left: 8.5%;
  color: #77FCFB;
 font-family: var(--main-font-family);
  font-size: var(--disclaimer-font-size);
 }
.all-rights-reserved {
  z-index: 1;
  margin-left: 24.5%;
  margin-top: 45%;
   color: #77FCFB;
  font-family: var(--main-font-family);
  font-size: var(--base-font-size);
}



.vendor-form-submission {
  position: absolute;
    top: 50.5%;
    left: 34.5%;
    height: 10%;
    width: 40%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}
@media screen and (max-width: 1200px){
  .vendor-form-submission{
   right: 35%;
   width: 30%;
  }
  
}
@media screen and (max-width: 992px){
  .vendor-form-submission{
    left: 24%;
    right: 31%;
    width:40%;
  }
  
}
.submission-date {
  position: absolute;
    top: 56%;
    left: 42%;
    height: 10%;
    width: 20%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}
@media screen and (max-width: 1200px){
  .submission-date{
   left: 40%;
  }
  
}
@media screen and (max-width: 992px){
  .submission-date{
   left: 35%;
  }
  
}
.please-keep-this-ref {
  position: absolute;
  top: 73%;
  left: 28%;
  height: 14px;
  width: 70%;
  color: #888E8E;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
@media screen and (max-width: 1200px){
  .please-keep-this-ref{
   left: 25%;
  }
  
}
@media screen and (max-width: 992px){
  .please-keep-this-ref{
   left: 13%;
   width: 75%;
  }
  
}
.button-download{
  position: absolute;
  border: none;
  cursor:pointer;
  overflow: hidden;
  top: 68.4%;
  left: 36%;
  height: 5%;
  width: 14%;
  border-radius: 19px;
  background-color: #F28F7B;
}

@media screen and (max-width: 1200px){
  .button-download{
   left: 24%;
  }
  
}
@media screen and (max-width: 992px){
  .button-download{
    top: 90%;
   left: 13%;
  }
  
}
.button-continueUpload{
    position: absolute;
    border: 1px solid #49a7a2;
    cursor: pointer;
    overflow: hidden;
    top: 86%;
    left: 51%;
    height: 8.5%;
    width: 16.5%;
    border-radius: 19px !important;
    background-color: #FFFFFF;
}
@media screen and (max-width: 1200px){
  .button-continueUpload{
    left: 52%;
    width: 20%;
    height: 8.5%;
  }
  
}
@media screen and (max-width: 992px){
  .button-continueUpload{
    left: 53%;
    width: 31%;
    height: 6.5%;
   top:86%;
  }
  
}
.download-report{
  position: absolute;
  top: 12px;
  left: 1%;
  height: 14px;
  color: #FFFFFF;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
  width: 100%;
}
.continueUpload{
  position: absolute;
  top: 35%;
  left: 17%;
  height: 10%;
  color: black;
  font-family: var(--main-font-family);
  font-size: var( --base-font-size );
  letter-spacing: 0;
  line-height: 14px;
}
.vendor-form-number{
  position: absolute;
    top: 50.5%;
    right: 13%;
    height: 15%;
    width: 31%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}
@media screen and (max-width: 1200px){
  .vendor-form-number{
   right: 9%;
  }
  
}
@media screen and (max-width: 992px){
  .vendor-form-number{
   right: -17%;
   left: 61%;
  }
  
}
.date{
  position: absolute;
    top: 56%;
    right: 33%;
    height: 10%;
    width: 15%;
    color: var(--main-txt-color);
    font-family: var(--main-font-family);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
}
.alphabh{
  font-size: 15px !important;
  font-family: var(--main-font-family-regular);
  
}
.omega{
  display: flex !important;
  flex-direction: row !important;
  margin-top: 20px !important;
}
.header{
  font-family: var(--main-font-family-bold);
  font-size: 25px;
  margin-top: -0px !important;
  margin-left: 60px;
}
.headerDiv{
  height: 40px;
  margin-top: 65px !important;
}
.border{
  height: 2px;
  background-color: #49a7a2;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 7px;
}
.image{
  margin-left: 115px !important;
}
.buttondiv-logout-warning{
  position: relative !important;
  border: none !important;

  left: 22%;
  height: 34px !important;
  width: 120px !important;
  border-radius: 19px !important;
  background-color:#F28F7B !important;
  color: white;
}
.buttondiv-logout-warning1{
  position: relative !important;
  border-width: 1px !important;
  left: 16%;
  height: 34px !important;
  width: 120px !important;
  border-radius: 19px !important;
  background-color:white !important;
  border-color: #49A7A2 !important;
  color: black;
  font-size: 13px !important;
}

@media screen and (max-width: 1200px){
  .date{
   right: 33%;
  }
  
}
@media screen and (max-width: 992px){
  .date{
   right: 22%;
   left: 52%;
  }
  
}
.homecoming{
  position: absolute;
  top: 5%;
  right: 3%;
}

@media screen and (max-width: 992px) and (min-width: 601px)
   {
    .all-rights-reserved-thanku{
      z-index: 1;
      width: 30%;
      margin-top: 57%;
      margin-left: 4%;
      color: #77FCFB;
     font-family: var(--main-font-family);
      font-size: var(--disclaimer-font-size);
     }
    .all-rights-reserved {
      z-index: 1;
      margin-left: 11%;
      margin-top: 58%;
       color: #77FCFB;
      font-family: var(--main-font-family);
      font-size: var(--base-font-size);
    }
   }
   @media screen and (max-width: 1200px) and (min-width: 992px){
    .all-rights-reserved {
      margin-left: 13%;
    }
    .all-rights-reserved-thanku{
      margin-left: 3%;
    }
   }